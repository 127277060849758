import React, { useEffect, useState } from "react";
import {
  GetQuestionsService,
  GetQuizByIdService,
  GetQuizQuestionsService,
} from "../../../services/QuizService";
import ReactHtmlParser from "react-html-parser";
import { clearQuiz, setQuizComplete } from "../../../store/actions";
import { useDispatch } from "react-redux";

export default function QuizDescription(props) {
  const {result, setresult}=props
  const [quiz, setquiz] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if(props.isAdmin !== 1){
      localStorage.removeItem("totaltime");
      GetQuizByIdService({ qid: props.quizId })
      .then((res) => {
        setquiz(res.data[0]);
        setresult(res.data[1]);
      })
      .catch((err) => console.log(err));
      
      return () => setquiz("");
    }
  }, []);

  return (
    <div className="d-flex">
      <div class="main-action-btns text-right">
        {/* <a
          onClick={() => props.setshow({ list: true })}
          class="btn btn-white-bordered  btn-back m-0"
        >
          <i class="fal fa-angle-left"></i>Back to Learning Material
        </a> */}
        {/* {result > 0 && (
          <a
            onClick={() => props.setshow({ attempt: true })}
            class="btn btn-white-bordered"
          >
            <i class="fal fa-clipboard-list"></i>Results List
          </a>
        )} */}
      </div>
      <div className="card card-header-transparent">
        <div className="card-body">
          <div className="table-responsive table__quize__desc">
            <table className="table table-bordered table-striped table-custom-info">
              <tbody>
                <tr>
                  <th>Assessment Name</th>
                  <td>{quiz.quiz_name}</td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{ReactHtmlParser(quiz.description)}</td>
                </tr>
                <tr>
                  <th>Duration (In Min.)</th>
                  <td>{quiz.duration}</td>
                </tr>
                <tr>
                  <th>Allow Maximum Attempts</th>
                  <td>{quiz.maximum_attempts}</td>
                </tr>
                <tr>
                  <th>Minimum Percentage Required To Pass</th>
                  <td>{quiz.pass_percentage}</td>
                </tr>
                <tr>
                  <th>Correct Score</th>
                  <td>{quiz.correct_score}</td>
                </tr>
                <tr>
                  <th>Incorrect Score</th>
                  <td>{quiz.incorrect_score}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="quiz-footer-btns">
            <button
              onClick={() => {
                // Proceed with the quiz service call
                GetQuestionsService({ quid: props.quizId })
                  .then((res) => {
                    dispatch(clearQuiz());

                    const obj = {};
                    res.data.questions
                      .filter(
                        (list) => list && list.questions && list.questions.length > 0
                      )
                      .forEach((q) => {
                        if (q.type === 0) {
                          obj[`qa${q.questions[0].qid}qa`] = q.questions[0];
                        } else {
                          obj[`qa${q.questions[0].qid}qa`] = q.questions;
                        }
                      });

                    localStorage.removeItem("totaltime");
                    // Remove localStorage items that start with "@"
                    Object.keys(localStorage)
                      .filter((key) => key.startsWith("@"))
                      .forEach((key) => localStorage.removeItem(key));

                    res.data.questions
                      .filter(
                        (list) => list && list.questions && list.questions.length > 0
                      )
                      .forEach((q) => {
                        q.questions.forEach((ques) => {
                          localStorage.setItem(`@qa${ques.qid}qa`, 0);
                        });
                      });

                    props.setshow({ desc: false, question: true });
                    dispatch(setQuizComplete(obj));
                  })
                  .catch((err) => console.error("Error in GetQuestionsService:", err))
                  .finally(() => setLoading(false));
              }}
              className={
                "btn btn-white-bordered btn-primary" +
                (quiz?.maximum_attempts > result ? "" : " disabled")
              }
              disabled={(quiz?.maximum_attempts > result ? "" : " disabled")}
            >
              <i className={loading ? "fas fa-cog fa-spin" : "fal fa-rocket"}></i>
              Start Quiz
            </button>

          </div>
        </div>
      </div>
    </div>
  );
}
