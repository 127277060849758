import React, { useState, useEffect, memo, useRef } from "react";
import VideoPlayer from "./VidoePlayer";
import QuizeImage from "../../assets/images/ic-1.png";
import ReactHtmlParser from "react-html-parser";
import {
  updateVideoTimeService,
  getVideoTimeService,
  getAssembliesItem,
  getAssembliesItemAdmin,
  fileTrackingService,
  updateWatchCountService,
} from "../../services/AssembliesService";
import { download } from "../../utils/FileDownload";
import { image_url } from "../../utils/ImageUrl";
import { useParams } from "react-router-dom";
import { ADMIN_PASSWORD } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { clearVideoDuration, UpdateLessonType, updateVideoDuration, updateVideoPlayer } from "../../store/actions";
import SkeletonSubject from "../../loaders/SkeletonSubject";
import ViewQuiz from "./quiz/ViewQuiz";
import ReviewQuiz from "./quiz/ReviewQuiz";
import QuizDescription from "./quiz/QuizDescription";
import ViewQuestion from "./quiz/ViewQuestion";
import { reverse } from "@tensorflow/tfjs";
import { TrimText } from "../common/TrimText";
import { MathJax } from "better-react-mathjax";
let obj = {};

function AssembliesItems(props) {
  const {
    assemblyItem,
    lesson,
    currentVideoId,
    buttonCheckedStatus,
    documentId,
    setQuizId,
    QuizId,
    setshow,
    setrId,
    show,
    handleNextActivity,
    handleBackActivity,
    activeTab,
    showactiveTab,
    rId,
    // showNext,
    nextActivity,
    getSubContentMethod,
    lessonindex,
    isAdmin,
    refreshProgress,
    lessonList,
    handlePrevActivity
  } = props;

  const dispatch = useDispatch();
  const currentAssembly = useSelector(state => state.learningMaterial.currentAssembly)
  const currentActivity=useSelector(state=>state.learningMaterial.current_activity)

  const lesson_type = useSelector(state => state.learningMaterial.active_lesson_type);
  const videoPlayerShow = useSelector(state => state.learningMaterial.videoPlayer);
  const lessonId = useSelector(state => state.learningMaterial.lessonId);
  const {password} = useParams();
  const [videoId, setVideoId] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [watchedVideo, setWatchedVideo] = useState([]);
  const [watchTime, setWatchTime] = useState(lesson?.watch_in_m_s);
  const [watchPercentage, setWatchPercentage] = useState(lesson?.watch_percentage);
  const [isWatchedVideo, setIsWatchedVideo] = useState(lesson?.is_video_watched);
  const [alreadyWatched, setAlreadyWatched] = useState(lesson?.alreadyWatched ? true : false);
  const [buttonCheckedStatus1, setButtonCheckedStatus1] = useState(0);
  const [documentId1, setDocumentId1] = useState([]);
  const [currentVideoId1, setCurrentVideoId1] = useState(0);
  const [playerSeek, setPlayerSeek] = useState(0);
  const [playerPipMode, setPlayerPipMode] = useState(false);
  const [resumeBtn, setResumeBtn] = useState(
    lesson?.watch_percentage > 0 && lesson?.watch_percentage < 100 ? true : false
  );
  const [result, setresult] = useState();
  const [list, setlist] = useState([]);
  const [lastLessonId, setLastLessonId]=useState(null)
  const [startLessonId, setStartLessonId]=useState(null)
  const[videoDuration, setVideoDuration]=useState(0)
  const [videoState, setVideoState] = useState({
    startDuration: 0,
    pauseDuration: 0,
  });
  const [buttonConfig, setButtonConfig] = useState({
    show: true,
    text: "Go to next activity"
  });
  useEffect(()=>{
    dispatch(clearVideoDuration())
  },[])

  useEffect(()=>{
    dispatch(updateVideoPlayer(false))
    setResumeBtn(false)
    props.parentSetCurrentVideoId("");
  },[lesson_type])

  const handleVideoTogglePIP = (player, lesson, videoId, action) => {
    // e.preventDefault()
    setPlayerPipMode(action);
  };
  const handleVideoStart = (player, lesson, videoId) => {
    setResumeBtn(true);
    setPlaying(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);

    //set current time of video to 0 on start video
    const watchedVd = 0;
    const duration = Math.round(player.getDuration());
    if(password!=ADMIN_PASSWORD){
      updateVideoTimeService(lesson, watchedVd, true, duration).then(
        (res) => {
          // setWatchTime(res.data[0].watchedTimeSecond);
        setWatchPercentage(res.data[0]?.watch_percentage);
          if (parseFloat(res.data[0]?.watch_percentage) >= 95) {
            updateWatchCountService(lesson)
              .then((res) => {
                // props.parentGetLearningMaterialData(props.parentSubId);
                // props.subassembliesItems(props.parentSubId);
              })
              .catch((error) => { });
          } else if (parseFloat(res.data[0]?.watch_percentage) >= 99) {
            setResumeBtn(false);
          }
  
          //Set resume time of video
          player.seekTo(res.data[0].watchedTimeInSecond);
          setPlayerSeek(res.data[0].watchedTimeInSecond);
        }
      );
    }

    props.parentSetCurrentVideoId(videoId);
    getSubContentMethod();
   
  };


  const setCustomeCurrentVideoId = (videoId) => {
    setCurrentVideoId1(videoId);
  };
  
  const handleFileTracking = (itemId) => {
    fileTrackingService(itemId).then((res) => {
      setButtonCheckedStatus1(true);
      setDocumentId1([...documentId1, itemId]);
      // props.subassembliesItems(props.parentSubId);
      // props.parentGetLearningMaterialData(props.parentSubId);
      props.refreshProgress(props.lesson?.module_id);
    });
    getSubContentMethod();
  };
  
  const handleVideoResume = (player, lesson, videoId, action) => {
    setResumeBtn(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);
    
    //get current time of video
    const watchedVd = Math.round(player.getCurrentTime());
    const duration = Math.round(player.getDuration());
    setVideoDuration(duration)
    
    if (action == false) {      
      setVideoState((prevState) => ({
        ...prevState,
        pauseDuration: watchedVd,
        isPaused: true,
      }));
      
      getVideoTimeService(lesson).then((res) => {
        // setWatchTime(res.data[0].watchedTimeSecond);
      setWatchPercentage(res.data[0]?.watch_percentage);
        
        //Set resume time of video
        //player.seekTo(res.data[0].watchedTimeInSecond);
        setPlayerSeek(res.data[0].watchedTimeInSecond);
        if (parseFloat(res.data[0]?.watch_percentage) >= 95) {
          updateWatchCountService(lesson)
          .then((res) => {
            // props.parentGetLearningMaterialData(props.parentSubId);
            // props.subassembliesItems(props.parentSubId);
          })
          .catch((error) => { });
        } else if (parseFloat(res.data[0]?.watch_percentage) >= 99) {
          setResumeBtn(false);
        }
      });
    } else {
      setVideoState((prevState) => ({
        ...prevState,
        startDuration: Math.round(player.getCurrentTime()),
        isPaused: false,
      }));
      let updateVideoTime = true;
      if(password!=ADMIN_PASSWORD){
        updateVideoTimeService(
          lesson,
          watchedVd,
          updateVideoTime,
          duration
        ).then((res) => {
          // setWatchTime(res.data[0].watchedTimeSecond);
        setWatchPercentage(res.data[0]?.watch_percentage);
          if (parseFloat(res.data[0]?.watch_percentage) >= 95) {
            updateWatchCountService(lesson)
              .then((res) => {
                // props.subassembliesItems(props.parentSubId);
                // props.parentGetLearningMaterialData(props.parentSubId);
              })
              .catch((error) => { });
          } else if (parseFloat(res.data[0]?.watch_percentage) >= 99) {
            setResumeBtn(false);
          }
          //Set resume time of video
          // player.seekTo(res.data[0].watchedTimeInSecond);
          setPlayerSeek(res.data[0].watchedTimeInSecond);
        });
      }
    }
    setPlaying(action);
    
    props.parentSetCurrentVideoId(videoId);
    getSubContentMethod();
  };

  const handleVideoInProgress = (player, lesson, videoId) => {
    // set(prev => !prev)
    // e.preventDefault()
    setResumeBtn(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);

    //get current time of video
    const watchedVd = Math.round(player.getCurrentTime());
    const duration = Math.round(player.getDuration());

    let updateVideoTime = true;
    if(password!=ADMIN_PASSWORD){
      updateVideoTimeService(
        lesson,
        watchedVd,
        updateVideoTime,
        duration
      ).then((res) => {
        setWatchPercentage(res.data[0]?.watch_percentage);
        if (parseFloat(res.data[0]?.watch_percentage) >= 95) {
          updateWatchCountService(lesson)
            .then((res) => {
              // props.parentGetLearningMaterialData(props.parentSubId);
              props.subassembliesItems(props.parentSubId);

            })
            .catch((error) => { });
        } else if (parseFloat(res.data[0]?.watch_percentage) >= 99) {
          setResumeBtn(false);
        }
        // props.parentGetLearningMaterialData(props.parentSubId);
        // props.subassembliesItems(props.parentSubId);
        props.refreshProgress(props.lesson?.module_id);
        setPlayerSeek(res.data[0].watchedTimeInSecond);
      });
    }

    // props.parentSetCurrentVideoId(videoId);
    getSubContentMethod();
   
  };
  
  const handleVideoRewind = (player, lesson, videoId) => {
    // e.preventDefault()
    setResumeBtn(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);

    //get current time of video
    const watchedVd = Math.round(player.getCurrentTime());
    const duration = Math.round(player.getDuration());

    let updateVideoTime = true;
    if(password!=ADMIN_PASSWORD){
      updateVideoTimeService(
        lesson,
        watchedVd,
        updateVideoTime,
        duration
      ).then((res) => {
        setWatchPercentage(res.data[0]?.watch_percentage);
        if (parseFloat(res.data[0]?.watch_percentage) >= 95) {
          updateWatchCountService(lesson)
            .then((res) => {
              // props.parentGetLearningMaterialData(props.parentSubId);
              props.subassembliesItems(props.parentSubId);
            })
            .catch((error) => { });
        } else if (parseFloat(res.data[0]?.watch_percentage) >= 99) {
          setResumeBtn(false);
        }
        // props.parentGetLearningMaterialData(props.parentSubId);
        // props.subassembliesItems(props.parentSubId);
        props.refreshProgress(props.lesson?.module_id);
      });
    }

    props.parentSetCurrentVideoId(videoId);
    getSubContentMethod();
   
  };
  

  const handleVideoReady = (player, lesson, videoId) => {
    setResumeBtn(true);
    setPlaying(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);
    const watchedVd = 0;
    let updateVideoTime = false;
    setAlreadyWatched(true);
    if(password!=ADMIN_PASSWORD){
      updateVideoTimeService(lesson, watchedVd, updateVideoTime).then(
        (res) => {
          setWatchTime(res.data[0].watchedTimeSecond);
          setWatchPercentage(res.data[0]?.watch_percentage);
          //  player.seekTo(res.data[0].watchedTimeInSecond);
          if (parseFloat(res.data[0]?.watch_percentage) >= 95) {
            updateWatchCountService(lesson)
              .then((res) => {
                // props.parentGetLearningMaterialData(props.parentSubId);
                // props.subassembliesItems(props.parentSubId);
              })
              .catch((error) => { });
          } else if (parseFloat(res.data[0]?.watch_percentage) >= 99) {
            setResumeBtn(false);
          }
          // props.parentGetLearningMaterialData(props.parentSubId);
          // props.subassembliesItems(props.parentSubId);
          props.refreshProgress(props?.lesson?.module_id);
          setPlayerSeek(res.data[0].watchedTimeInSecond);
        }
      );
    }
    props.parentSetCurrentVideoId(videoId);
   
  };

  const downloadFile = async (absolutePath, fileName, item) => {
    if (isAdmin != 1) {
      props.parentFileTracking(item);
    }
    let value = await download(absolutePath, fileName);
  };

  const handleViewItem = async (item) => {
    if (isAdmin != 1) {
      props.parentFileTracking(item);
    }    
  };

  const fetchIcon = (path) => {
    let ext = path?.split('.')
    switch (ext[ext?.length - 1]) {
      case 'docx':
        return 'fal fa-file-word';
        break;
      case 'zip':
        return 'fal fa-file-archive';
        break;
      case 'pdf':
        return 'fal fa-file-pdf';
        break;
      case 'xlsx':
        return 'fal fa-file-excel';
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
        return 'fal fa-file-image';
        break;
      case 'txt':
        return 'fal fa-file-alt';
        break;
      case 'pptx':
        return 'fal fa-file-powerpoint';
        break;
      default:
        return 'fal fa-file';
        break;
    }
  }

  useEffect(()=>{
    if(((lesson?.item_type == 8 && lesson?.all_multiple_documents.length < 1) || lesson?.item_type == 7) && isAdmin !== 1 ){
      props.parentFileTracking(lesson);
    }  
  },[lesson?.item_id])

  useEffect(() => {
    //checking last lesson 
    const lastAssembly = props.assembliesItems?.assembliesItems?.[props.assembliesItems.assembliesItems.length - 1];
    const firstAssembly = props.assembliesItems?.assembliesItems[0]
    const lastLesson = lastAssembly?.lessons?.[lastAssembly.lessons.length - 1];
    const firstLesson = firstAssembly?.lessons[0];
    setStartLessonId(firstLesson?.item_id)
    // Check if lastLesson has sub-children (assuming sub-children are in `subChildren` property)
    const lastSubChildId = lastLesson?.sub_children?.[lastLesson.sub_children.length - 1]?.item_id;
    // If no sub-children, use the last item's id
    if(lastSubChildId){
      setLastLessonId(lastSubChildId)
    }else{
      setLastLessonId(lastLesson?.item_id)
    }
  },[currentActivity])

  useEffect(() => {
    if (!lessonList?.assembliesItems?.length || !lesson?.item_id) return;
  
    const checkIfLastItem = () => {
      const assemblies = lessonList.assembliesItems;
      let hasLastSubLesson = false;
      let isLast = true;
      let hasNext = false;
      let nextIsModule = false;
  
      // Find current assembly and lesson
      for (let i = 0; i < assemblies.length; i++) {
        const currentAssembly = assemblies[i];
        const lessons = currentAssembly.lessons || [];
  
        for (let j = 0; j < lessons.length; j++) {
          const currentLesson = lessons[j];
          let nextLesson=lessons[j + 1];
  
          // Check if next lesson is type 3
          if (nextLesson?.item_type === 3) {
            nextIsModule = true;
          }else{
            nextIsModule = false;
          }
  
          // Check if in sub_children
          if (currentLesson.sub_children?.length) {
            const subIndex = currentLesson.sub_children.findIndex(
              sub => sub.item_id === lesson.item_id
            );
  
            if (subIndex === currentLesson.sub_children?.length - 1) {
              hasLastSubLesson = true;
            }
  
            if (subIndex !== -1) {
              // Has more items in current sub_children
              if (subIndex < currentLesson.sub_children.length - 1) {
                isLast = false;
                hasNext = true;
              }
              // Last in sub_children, check if more lessons exist
              else if (j < lessons.length - 1) {
                isLast = false;
                hasNext = true;
              }
              // Last lesson, check if more assemblies exist
              else if (i < assemblies.length - 1 && assemblies[i + 1]?.lessons?.length) {
                isLast = false;
                hasNext = true;
              }
  
              // If next lesson in the next assembly is of type 3
              if (subIndex === currentLesson.sub_children.length - 1 && i < assemblies.length - 1) {
                const nextAssembly = assemblies[i + 1];
                const nextLessonInAssembly = nextAssembly?.lessons?.[0]; // Get first lesson in the next assembly
                if (nextLessonInAssembly && nextLessonInAssembly.type === 3) {
                  nextIsModule = true;
                }
              }
  
              return { isLast, hasNext, hasLastSubLesson, nextIsModule };
            }
          }
  
          // Check main lesson
          if (currentLesson.item_id === lesson.item_id) {
            // Has more lessons in current assembly
            if (j < lessons.length - 1) {
              isLast = false;
              hasNext = true;
            }
            // Last lesson, check if more assemblies exist
            else {
              for (let j = i + 1; j < assemblies.length; j++) {
                if (assemblies[j]?.lessons?.length) {
                  isLast = false;
                  hasNext = true;
                }
              }
            }
  
            // Check if next lesson is of type 3
            if (i < assemblies.length - 1) {
              const nextLessonInAssembly = assemblies[i + 1]?.lessons?.[0]; // Assuming next lesson is the first in the next assembly
              if (nextLessonInAssembly && nextLessonInAssembly.type === 3) {
                nextIsModule = true;
              }
            }
  
            return { isLast, hasNext, hasLastSubLesson, nextIsModule };
          }
        }
      }
  
      return { isLast, hasNext, hasLastSubLesson, nextIsModule };
    };
  
    const { isLast, hasNext, hasLastSubLesson, nextIsModule } = checkIfLastItem();
    setButtonConfig({
      show: hasNext,
      text: (hasLastSubLesson || nextIsModule) ? "Go to next module" : "Go to next activity"
    });
    
  
  }, [lesson?.item_id, lessonList]);

  return (
    <>
    <li key={lesson?.item_id}>
        <div className={"sub-body" + (lesson?.item_type === 2 ? ' video-sec' : '')}>
          {currentActivity == lesson?.item_id && (lesson_type == 2 || lesson?.item_type === 2) ? (
            <VideoPlayer
              buttonConfig={buttonConfig}
              lastLessonId={lastLessonId}
              currentActivity={currentActivity}
              setUpdateLearningMaterialData={props.setUpdateLearningMaterialData}
              isAdmin={isAdmin}
              ParentResumeBtn={resumeBtn}
              ParentHandleVideoStart={handleVideoStart}
              playing={playing}
              videoId={videoId}
              handleNextActivity={handleNextActivity}
              lessonindex={lessonindex}
              assemblyId={lesson?.assembly_id}
              loopId={lesson?.item_id}
              assemblyName={lesson?.item_name}
              assemblyDescription={lesson?.item_desc}
              videoType={lesson?.video_type}
              url={lesson?.youtube_url}
              item_url={lesson?.item_url}
              parentRefId={`${lesson?.parent_id}_${lesson?.item_id}`}
              watchedVideo={watchedVideo}
              video_duration={lesson?.video_duration}
              ParentHandleVideoResume={handleVideoResume}
              ParentHandleVideoInProgress={handleVideoInProgress}
              ParentHandleVideoReady={handleVideoReady}
              ParentHandleVideoRewind={handleVideoRewind}
              watch_in_m_s={watchTime}
              watchPercentage={watchPercentage}
              isWatchedVideo={isWatchedVideo}
              alreadyWatched={alreadyWatched}
              currentVideoId={currentVideoId}
              playerSeek={playerSeek}
              ParentPlayerPipMode={playerPipMode}
              ParentHandleVideoTogglePIP={handleVideoTogglePIP}
              lessonItemId={lesson?.item_id}
              assembliesItems={assemblyItem}
              videoState={videoState}
              setPlayerSeek={setPlayerSeek}
              videoDuration={videoDuration}
              handlePrevActivity={handlePrevActivity}
              startLessonId={startLessonId}
            />
            
          ) : null}

          {/* {/* {assemblyItem.item_type === 3 ? (
            <div
              className="hd-main d-flex flex-wrap"
              data-toggle="collapse"
              data-parent="#accordion"
              onClick={() => handleItem(assemblyItem.item_id)}
              href={"#accordionBodyOne" + assemblyItem.item_id}
              key={assemblyItem.item_id}
            >
              <div className="sub-header">
                <h3>{assemblyItem.item_name}</h3>
                <p>{assemblyItem.item_desc}</p> */}
          {currentActivity == lesson?.item_id && (!videoPlayerShow && lesson?.item_type == 8) ? (
            <div className="lesson__content">
              <div className="card-list-inner d-flex " style={{paddingBottom:"20px"}}>
                <div className="sub-header-data multiformat__lesson">
                  <div className="label__lesson__title">
                    {lesson?.item_name}
                  </div>
                  {/* <p className="m-0">{new DOMParser().parseFromString(
                    lesson?.item_desc,
                    "text/html"
                  ).body.textContent}</p> */}
                  <MathJax inline dynamic>
                    {lesson?.item_desc ? ReactHtmlParser(lesson?.item_desc): null}
                  </MathJax>
                </div>
              </div>
              {lesson?.all_multiple_documents.length > 0 ? <>
                <div className="lesson__detail__doc">File </div>
                {lesson?.all_multiple_documents?.map((item, index) =>
                  <div className="lesson__card__container">
                    <div className="lesson__file__icon">
                      {(() => {
                        const documentExt = item?.docs_path?.split(".").pop();
                        // const uploadLinkExt = lesson?.upload_link?.split(".").pop();
                        const fileExt = documentExt
                        // || uploadLinkExt;
                        return fileExt ? (
                          {
                            // Document icons
                            doc: <i title="Document" className="fal fa-file-word"></i>,
                            docx: <i title="Document" className="fal fa-file-word"></i>,
                            pdf: <i title="Document" className="fal fa-file-pdf"></i>,
                            ppt: <i title="Document" className="fal fa-file-powerpoint"></i>,
                            xlsx: <i title="Document" className="fal fa-file-excel"></i>,
                            zip: <i title="Document" className="fal fa-file-archive"></i>,

                            // Image icons (with more formats)
                            jpg: <i title="Image" className="fal fa-image"></i>,
                            jpeg: <i title="Image" className="fal fa-image"></i>,
                            png: <i title="Image" className="fal fa-image"></i>,
                            gif: <i title="Image" className="fal fa-image"></i>,
                            svg: <i title="Image" className="fal fa-image"></i>,
                            bmp: <i title="Image" className="fal fa-image"></i>,
                            tiff: <i title="Image" className="fal fa-image"></i>,
                            webp: <i title="Image" className="fal fa-image"></i>,
                            ico: <i title="Image" className="fal fa-image"></i>,
                            jfif: <i title="Image" className="fal fa-image"></i>,  // Added JFIF support

                            // Add more formats if necessary
                          }[fileExt] || (
                            <>
                              {/* Default generic file icon */}
                              <i title="File" className="fal fa-file"></i>
                            </>
                          )
                        ) : null;
                      })()}
                    </div>
                    <div className="card-list-inner d-flex card__doc__lesson">
                      <div className="card-list-left">
                        <p className="lead-text-sm m-0 lesson__doc__name">
                          {item?.docs_name.includes(": ") ? item?.docs_name.split(": ")[1] : item?.docs_name}
                          {/* {TrimText(lesson?.item_name.includes(": ") ? lesson?.item_name.split(": ")[1] : lesson?.item_name,28)} */}
                        </p>
                        <div className="lesson__doc__details">
                          {item?.docs_size ? <p className="doc__size">Size:<span>{item?.docs_size || "NA"}</span></p> : null}
                          <p className="doc__type">
                            Type:
                            {(() => {
                              const documentExt = item?.docs_path?.split(".").pop();
                              // const uploadLinkExt = item?.upload_link?.split(".").pop();
                              const fileExt = documentExt
                              // || uploadLinkExt;

                              // Mapping file extensions to descriptive names
                              const fileTypes = {
                                doc: "Word Document",
                                docx: "Word Document",
                                pdf: "PDF Document",
                                ppt: "PowerPoint Document",
                                xlsx: "Excel Spreadsheet",
                                zip: "Zip Folder",
                                jpg: "Image File",
                                jpeg: "Image File",
                                png: "Image File",
                                gif: "Image File",
                                svg: "Image File",
                                bmp: "Image File",
                                tiff: "Image File",
                                webp: "Image File",
                                ico: "Image File",
                                jfif: "Image File",
                              };

                              // Return the type or 'Unknown Type' if not recognized
                              return <span>{fileExt ? fileTypes[fileExt] || 'Unknown Type' : 'Unknown Type'}</span>
                            })()}
                          </p>
                        </div>
                      </div>
                      <div className="card-list-right ml-auto">
                        <ul className="learningright-inner d-flex align-items-center">
                          <li className="border-left item-action">
                            <div className="media-gp">
                              <a
                                title="Download"
                                onClick={() => {
                                  // lesson?.document,
                                  downloadFile(
                                    item?.docs_path,
                                    item.docs_name + "." + (item.docs_path?.split(".").reverse()[0]),
                                    item
                                  );
                                }}
                                className="btn btn-white-bordered btn__download"
                              >
                                {/* {assemblyItem?.progressPercentag}% */}
                                <i className="fal fa-download"></i>Download
                              </a>
                              {item.buttonCheckedStatus ? (
                                <li className="item-status">
                                  <div className="header-status ">
                                    <div className="status-gol bg-success">
                                      <i className="fal fa-check"></i>
                                    </div>
                                  </div>
                                </li>
                              ) : null}
                            </div>
                          </li>
                        
                        </ul>
                      </div>
                    </div>
                  </div>)}
              </> : null}
            </div>
          ) : null}
          {currentActivity == lesson?.item_id && (!videoPlayerShow && lesson?.item_type === 4) ? (
            <div className="lesson__content">
              <div className="lesson__detail__doc">File Download</div>
              <div className="lesson__card__container">
                <div className="lesson__file__icon">
                  {(() => {
                    const documentExt = lesson?.documentName?.split(".").pop();
                    const uploadLinkExt = lesson?.upload_link?.split(".").pop();
                    const fileExt = documentExt || uploadLinkExt;
                    return fileExt ? (
                      {
                        // Document icons
                        docx: <i title="Document" className="fal fa-file-word"></i>,
                        doc: <i title="Document" className="fal fa-file-word"></i>,
                        pdf: <i title="Document" className="fal fa-file-pdf"></i>,
                        ppt: <i title="Document" className="fal fa-file-powerpoint"></i>,
                        xlsx: <i title="Document" className="fal fa-file-excel"></i>,
                        zip: <i title="Document" className="fal fa-file-archive"></i>,

                        // Image icons (with more formats)
                        jpg: <i title="Image" className="fal fa-image"></i>,
                        jpeg: <i title="Image" className="fal fa-image"></i>,
                        png: <i title="Image" className="fal fa-image"></i>,
                        gif: <i title="Image" className="fal fa-image"></i>,
                        svg: <i title="Image" className="fal fa-image"></i>,
                        bmp: <i title="Image" className="fal fa-image"></i>,
                        tiff: <i title="Image" className="fal fa-image"></i>,
                        webp: <i title="Image" className="fal fa-image"></i>,
                        ico: <i title="Image" className="fal fa-image"></i>,
                        jfif: <i title="Image" className="fal fa-image"></i>,  // Added JFIF support

                        // Add more formats if necessary
                      }[fileExt] || (
                        <>
                          {/* Default generic file icon */}
                          <i title="File" className="fal fa-file"></i>
                        </>
                      )
                    ) : null;
                  })()}
                </div>
                <div className="card-list-inner d-flex card__doc__lesson">
                  <div className="card-list-left">
                    <p className="lead-text-sm m-0 lesson__doc__name">
                    {lesson?.item_name.includes(": ") ? lesson?.item_name.split(": ")[1] : lesson?.item_name}
                    {/* {TrimText(lesson?.item_name.includes(": ") ? lesson?.item_name.split(": ")[1] : lesson?.item_name,28)} */}
                    </p>
                    <div className="lesson__doc__details">
                      <p className="doc__size">Size:<span>{lesson?.document_size || "NA"}</span></p>
                      <p className="doc__type">
                        Type:
                        {(() => {
                          const documentExt = lesson?.documentName?.split(".").pop();
                          const uploadLinkExt = lesson?.upload_link?.split(".").pop();
                          const fileExt = documentExt || uploadLinkExt;

                          // Mapping file extensions to descriptive names
                          const fileTypes = {
                            doc: "Word Document",
                            docx: "Word Document",
                            pdf: "PDF Document",
                            ppt: "PowerPoint Document",
                            xlsx: "Excel Spreadsheet",
                            zip: "Zip Folder",
                            jpg: "Image File",
                            jpeg: "Image File",
                            png: "Image File",
                            gif: "Image File",
                            svg: "Image File",
                            bmp: "Image File",
                            tiff: "Image File",
                            webp: "Image File",
                            ico: "Image File",
                            jfif: "Image File",
                          };

                          // Return the type or 'Unknown Type' if not recognized
                          return <span>{fileExt ? fileTypes[fileExt] || 'Unknown Type' : 'Unknown Type'}</span>
                        })()}
                      </p>
                    </div>
                  </div>
                  <div className="card-list-right ml-auto">
                    <ul className="learningright-inner d-flex align-items-center">
                      <li className="border-left item-action">
                        <div className="media-gp">
                          <a
                            title="Download"
                            onClick={() => {
                              // lesson?.document,
                              downloadFile(
                                lesson?.upload_type === "library" ? image_url(lesson?.upload_link) : lesson?.absolutePath,
                                lesson?.item_name + "." + (lesson?.upload_type === "library" ? lesson?.upload_link?.split(".").reverse()[0] : lesson?.documentName?.split(".").reverse()[0]),
                                lesson
                              );
                            }}
                            className="btn btn-white-bordered btn__download"
                          >
                            <i className="fal fa-download"></i>Download
                          </a>
                          {lesson?.buttonCheckedStatus ||
                            (buttonCheckedStatus &&
                              documentId.includes(lesson?.item_id)) ? (
                            <li className="item-status">
                              <div className="header-status ">
                                <div className="status-gol bg-success">
                                  <i className="fal fa-check"></i>
                                </div>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* Information */}
          {currentActivity == lesson?.item_id && (lesson_type !==3 && !videoPlayerShow && lesson_type !== 2 &&  lesson?.item_type === 7) ? (
            <div className="lesson__content">
              <div className="card-list-inner d-flex ">
                <div className="sub-header-data">
                  <p className="label__lesson__title">
                    {lesson?.item_name}
                  </p>
                  {lesson?.label_show_description == 1 ? <div className="m-0"> {lesson?.item_desc ? ReactHtmlParser(lesson?.item_desc): null}</div> : null}
                </div>
              </div>
            </div>
          ) : null}
      

          {currentActivity == lesson?.item_id && (lesson_type !== 3 && !videoPlayerShow && lesson?.item_type === 5) ? (
            <div className="lesson__content">
              <div className="lesson__detail__doc">
                <p className="lesson__link__name">{lesson?.item_name}</p>
                <div className="lesson__link__desc"> {lesson?.item_desc ? ReactHtmlParser(lesson?.item_desc): null}</div>
              </div>
              <div className="lesson__card__container align-items-start">
                <div className="lesson__file__icon">
                  <i className="fal fa-external-link"></i>
                </div>
                <div className="card-list-inner d-flex card__doc__lesson">
                  <div className="card-list-left">
                    <p className="lead-text-sm m-0 lesson__doc__name">
                      {lesson?.item_name}
                    </p>
                    <p className="link__url">
                      {lesson?.link_url}
                    </p>
                  </div>
                  <div className="card-list-right ml-auto">
                    <ul className="learningright-inner d-flex align-items-center">
                      <li title="View" className="border-left item-action">
                        <div className="media-gp">
                          <a
                            title="View"
                            // onClick={() => { handleViewItem(lesson?.item_id) }}
                            onClick={() => { handleViewItem(lesson) }}
                            href={lesson?.link_url}
                            target="_blank"
                            className="btn btn-white-bordered btn__download"
                          >
                            <i className="fal fa-eye"></i> View
                          </a>
                          {lesson?.buttonCheckedStatus ? (
                            <li className="item-status">
                              <div className="header-status ">
                                <div className="status-gol bg-success">
                                  <i className="fal fa-check"></i>
                                </div>
                              </div>
                            </li>
                          ) : (
                            null
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {currentActivity == lesson?.item_id && (lesson_type !== 3 && !videoPlayerShow && lesson?.item_type === 6) ? (
            <div className="d-flex flex-column">
              {(show?.desc || show?.attempt || show?.review) ? <div className="card-list-inner d-flex align-items-center w-100" style={{ marginBottom: "15px" }}>
                <div className="card-list-left">
                  <div className="card-list-ic d-flex">
                    <div className="card-media card-red-1 icon__div">
                      <i className="fal fa-ballot" />
                      {/* <img src={QuizeImage} alt="" /> */}
                    </div>
                    <div className="card-media-text d-flex justify-content-center flex-column" style={{gap:"10px"}}>
                      <p className="quize__name">
                        {lesson?.item_name}
                      </p>
                      {(lesson?.buttonCheckedStatus) ? (
                        (lesson?.QuizResult.length > 0) && (
                          (() => {
                            const maxItem = lesson?.QuizResult.reduce((max, item) =>
                              item?.percentage_obtained > max?.percentage_obtained ? item : max, lesson?.QuizResult[0]);
                            return <div className="progress border-left w-100">
                              <div
                                className="progress-bar progress-bar-text"
                                role="progressbar"
                                style={{
                                  width: ~~maxItem?.percentage_obtained + "%"
                                }}
                                aria-valuenow={~~maxItem?.percentage_obtained}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {~~maxItem?.percentage_obtained+"%"}
                              </div>
                            </div>
                          })()
                        )
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="card-list-right ml-auto">
                  <ul className="learningright-inner d-flex align-items-center">

                    <li className="border-left">
                      <div className="media-gp">
                        {show?.review ? <a
                          onClick={() => setshow({
                            list: true,
                            review: false,
                            attempt: false,
                            desc: true,
                            question: false
                          })}
                          class="btn btn-white-bordered  btn-back m-0"
                        >
                          <i class="fal fa-angle-left"></i>Back
                        </a> : null}
                        {/* {lesson?.button_status && (
                        <a
                          title="Result"
                          onClick={() => {
                            if (lesson?.button_status) {
                              setQuizId(lesson?.quiz_id);
                              setshow({ attempt: true });
                            }
                          }}
                          className="btn btn-white-bordered"
                          style={{
                            cursor: lesson?.button_status
                              ? "pointer"
                              : "not-allowed",
                          }}
                        >
                          <i className="fal fa-sign-in-alt"></i> Result
                        </a>
                      )} */}

                        {(show?.review || show?.attempt) ? isAdmin == 1 ? (<button
                          title="Attempt"
                          disabled="disabled"
                          className="btn btn-white-bordered ml-2">
                          <i className="fal fa-undo-alt"></i> Attempt Again
                        </button>) :
                          <a
                            title="Attempt"
                            onClick={() => {
                              if (!lesson?.maximum_attempts) {
                                setQuizId(lesson?.quiz_id);
                              }
                              setshow({
                                review: false,
                                attempt: false,
                                desc: true,
                                list:true,
                                question: false
                              });
                            }}
                            className="btn btn-white-bordered"
                            style={
                              lesson?.maximum_attempts
                                ? {
                                  cursor: "not-allowed",
                                  backgroundColor: "#d7dee8",
                                }
                                : {
                                  cursor: "pointer",
                                }
                            }
                          >
                            <i className="fal fa-undo-alt"></i> Attempt Again
                          </a> : null}
                        {(((show?.desc)) && lesson?.buttonCheckedStatus) && (
                          <a
                            onClick={() => setshow({
                              attempt: true,
                              desc: false
                            })}
                            class="btn btn-white-bordered result__btn"
                          >
                            <i class="fal fa-clipboard-list"></i>Results List
                          </a>
                        )}
                        {(lesson?.buttonCheckedStatus || show?.review) ? (
                          <li className="item-status">
                            <div className="header-status ">
                              <div className="status-gol bg-success">
                                <i className="fal fa-check"></i>
                              </div>
                            </div>
                          </li>
                        ) : (
                          <li className="item-status">
                            <div className="header-status">
                              <div
                                className="status-gol quize__not__started__icon"
                                data-toggle="dropdown"
                              >
                                <i className="fal fa-ellipsis-h"></i>
                              </div>
                              {/* <div className="dropdown-menu dropdown-menu-right">
                            <button title="Action" className="dropdown-item" type="button">
                              Action
                            </button>
                            <button title="Another action" className="dropdown-item" type="button">
                              Another action
                            </button>
                            <button title="Something else here" className="dropdown-item" type="button">
                              Something else here
                            </button>
                          </div> */}
                            </div>
                          </li>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              </div> : null}
              {show.attempt && <ViewQuiz isAdmin={props.isAdmin} list={list} setlist={setlist} quizId={lesson?.quiz_id} setQuizId={setQuizId} setshow={setshow} setrId={setrId} rId={rId} />}
              {show.desc && <QuizDescription  isAdmin={props.isAdmin} lesson={lesson} result={result} setresult={setresult} quizId={lesson?.quiz_id} setQuizId={setQuizId} setshow={setshow} setrId={setrId} rId={rId} />}
              {show.question && <ViewQuestion  isAdmin={props.isAdmin} lesson={lesson} parentFileTracking={props.parentFileTracking} result={result} setresult={setresult} quizId={lesson?.quiz_id} setQuizId={setQuizId} setshow={setshow} setrId={setrId} rId={rId} />}
              {show.review && <ReviewQuiz  isAdmin={props.isAdmin} result={result} setresult={setresult} quizId={lesson?.quiz_id} setQuizId={setQuizId} setshow={setshow} setrId={setrId} rId={rId} />}             </div>

          ) : null}
            {/* sub-category */}

          {/* {lesson_type==3 && lesson?.item_type === 3 ? (
            <div>
              <ul className="sub-ul">
                  {subassembliesItems.length
                    ? subassembliesItems.map((lesson1, index1) => {
                      return (
                        <SubAssembliesItems
                          isAdmin={isAdmin}
                          key={index1}
                          parentSubId={lesson?.item_id}
                          // parentGetLearningMaterialData={
                          //   handleItem
                          // }
                          assemblyItem={lesson1}
                          setQuizId={setQuizId}
                          lessonindex={
                            lessonindex != ""
                              ? lessonindex + "." + parseInt(index1 + 1)
                              : ""
                          }
                          setshow={setshow}
                          setrId={setrId}
                          lesson={lesson}
                          parentSetCurrentVideoId={setCustomeCurrentVideoId}
                          parentFileTracking={handleFileTracking}
                          currentVideoId={currentVideoId1}
                          getSubContentMethod={getSubContentMethod}
                          buttonCheckedStatus={buttonCheckedStatus1}
                          documentId={documentId1}
                          refreshProgress={refreshProgress}
                          refreshParentProgress={()=>{props.parentGetLearningMaterialData(props.parentSubId)}}
                        />
                      );
                    })
                    : null}
                </ul>
              </div>
          ) : null} */}
         
          {lesson?.item_type !== 2 ? <div className="learning__bottom__btn">
            {startLessonId !== currentActivity && <div className="footer-left-btn"> <button className="btn btn-white-bordered btn-primary prev-activity" onClick={handlePrevActivity}>
              <i className="fal fa-chevron-left"></i><span>Back</span>
            </button> </div>}
            {(lastLessonId !== currentActivity && buttonConfig.show) ? <div className="footer-right-btn">
              <button className="btn btn-white-bordered btn-primary next-activity" onClick={handleNextActivity}>
                <span>Continue</span><i className="fal fa-chevron-right"></i>
              </button> </div> : null}
          </div> : null}
        </div>
      </li>
    </>
  );
}
export default memo(AssembliesItems)