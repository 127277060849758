import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
//import { connect } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { selectTab, setIsExamLaunched } from "../../store/actions/index";
// const handleTabStatus =(tabId)=>{
// 	setCurrentTab(tabId);
// }
import { useLocation } from "react-router-dom";
import { getMenuItem } from "./Helper";

const SideBar = (props) => {
  const [count, setCount] = useState(0);
  const { pathname } = useLocation();
  const history = useHistory()

  let currentTabValue = pathname.split("/");
  const notificationcount = useSelector((state) => state.notification);
  const ticketNotification = useSelector((state) => state.ticketNotification);
  const chatNotification = useSelector((state) => state.chatNotification);
  const courseCount = useSelector((state) => state.course);

  const selectorState = useSelector((state) => state);
  const { user, reRegistrationCount, isReReg, isWithHeld, isExamReg, isArticulation } = selectorState
  const dispatch = useDispatch();
  const checkMissingKey = useSelector((state) => state.missingDetail.missingDetail);
  const [currentTab, setCurrentTab] = useState(1);
  const examReg = localStorage.getItem("examReg");
  const reReg = localStorage.getItem("reReg");
  const tabId = useSelector((state) => state.tabs.tab);
  const [reRegCount, setReRegCount] = useState("");
  const { examLaunched } = useSelector(state=>state.examLaunched); 
  const isExamLaunched = history.location.pathname === examLaunched?.url && examLaunched?.isExamLaunched;
  const examPopup = () => dispatch(setIsExamLaunched({...examLaunched, isClicked: true}));
  // const [checkMissingKey, setCheckMissingKey] = useState(false);
  // useEffect(() => {
  //   getMyAllExamCountService()
  //     .then((res) => {
  //       setexamreg(res.data.registration.examList > 0 ? true : false);
  //       setexamrereg(
  //         res.data.reregistration.open + res.data.reregistration.close > 0
  //           ? true
  //           : false
  //       );
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  // checkUserKeys.map(item => {
  //   if(!user[item]){
  //     dispatch(setUserMissingDetails(true))
  //   }
  // })

  useEffect(() => {
    setCount(courseCount.currentCount)
  }, [props.count])

  // useEffect(() => {
  //   checkUserKeys.map(item => {
  //     if(!user[item]){
  //       dispatch(setUserMissingDetails(true))
  //       // history.push("/forceAboutDetails")
  //     }
  //   })
  // }, [user])

  var Tabs = [
    {
      name: "Home",
      class: "fa-home",
      refLink: "examregistration",
      id: 15,
    },
    {
      name: "Home",
      class: "fa-home",
      refLink: "reregStatus",
      id: 13,
    },
    {
      name: "Home",
      class: "fa-home",
      refLink: "status",
      id: 0,
    },
    {
      name: "Home",
      class: "fa-home",
      refLink: "home",
      id: 1,
    },
    {
      name: "My Profile",
      class: "fa-user",
      refLink: "profile",
      id: 2,
    },
    // {
    //   name: "Chats",
    //   class: "fa-comment-alt",
    //   refLink: "mychats",
    //   id: 3,
    // },
    {
      name: "My Courses",
      class: "fa-laptop",
      refLink: "mycourses",
      id: 4,
    },
    {
      name: "My Tickets",
      class: "fa-ticket-alt",
      refLink: "mytickets",
      id: 5,
    },
    // {
    //   name: "New Tickets",
    //   class: "fa-ticket-alt",
    //   refLink: "myticketsnew",
    //   id: 5,
    // },
    {
      name: "Notifications",
      class: "fa-bell",
      refLink: "mynotifications",
      id: 6,
    },
    {
      name: "Calendar",
      class: "fa-calendar-alt",
      refLink: "calendar",
      id: 10,
    },

    {
      name: "Exam Registration",
      class: "fa-clipboard",
      refLink: "myexam",
      id: 8,
    },
    {
      name: "Re-Registration",
      class: "fa-clipboard-list",
      refLink: "reregistration",
      id: 9,
    },
    {
      name: "Student Hub",
      class: "fa-info-circle",
      refLink: `student-hub`,
      id: 11,
    },
    {
      name: "Home",
      class: "fa-home",
      refLink: "forceAboutDetails",
      id: 12,
    },
    {
      name: "Home",
      class: "fa-home",
      refLink: "ResultWithheld",
      id: 14,
    },
    
    // {
    //   name: "Feedback",
    //   class: "fa-handshake-alt",
    //   refLink: "complimentcomments",
    //   id: 7,
    // },
   
  ];

  // const reRegStatus = false;
  // const reRegStatus = JSON.parse(localStorage.getItem('reRegStatus'));
  // console.log("-------------------isrereg------------------",isReReg.isReReg);
  const ArticulationStatus = isArticulation.isArticulation;
  const reRegStatus = isReReg.isReReg;
  const examRegStatus = isExamReg.isExamReg;
  const isWithHeldStatus = localStorage.getItem("isResultWitheld");
  // console.log("pathname->>>>",currentTabValue.length === 3);
  if(currentTabValue.length === 2 && currentTabValue[1] === "reregistration" && (!reRegStatus && !reReg) && user.status !== "On Hold"){
    history.push("/reregStatus");
  }

  return (
    <>
      <div className="sidebar-left">
        <ul className="list-unstyled side-bar-nav m-0">
          {Tabs.map((tab, index) => {
            if (tab.id === 13 && !checkMissingKey && reRegStatus && user.status !== "On Hold" && isWithHeldStatus != "true" ){
              return (
                <li
                  title={tab.id === 13 ? "Student Portal Home" : "Log and track your tickets with the AIE support team"}
                  key={tab.id}
                  onClick={() => isExamLaunched ? examPopup() : dispatch(selectTab({ tab: tab.id }))}
                >{isExamLaunched
                  ? <Link
                    className={
                      tab.refLink === getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      {tab.id === 5 && ticketNotification.ticketnotificationcount > 0 && (
                        <small className="notify-count">
                          {ticketNotification.ticketnotificationcount > 9
                            ? "9+"
                            : ticketNotification.ticketnotificationcount}
                        </small>
                      )}

                      <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </Link>
                  :
                  <NavLink
                    to={("/", "/" + tab.refLink)}
                    className={
                      tab.refLink === getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      {tab.id === 5 && ticketNotification.ticketnotificationcount > 0 && (
                        <small className="notify-count">
                          {ticketNotification.ticketnotificationcount > 9
                            ? "9+"
                            : ticketNotification.ticketnotificationcount}
                        </small>
                      )}

                      <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </NavLink>
                  }
                </li>
              )
            }
            else if (tab.id === 15 && !checkMissingKey && examRegStatus && !reRegStatus && user.status !== "On Hold" && isWithHeldStatus != "true" ){
              return (
                <li
                  title={tab.id === 15 ? "Student Portal Home" : "Log and track your tickets with the AIE support team"}
                  key={tab.id}
                  onClick={() => isExamLaunched ? examPopup() : dispatch(selectTab({ tab: tab.id }))}
                >{isExamLaunched
                  ? <Link
                    className={
                      tab.refLink === getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      {tab.id === 5 && ticketNotification.ticketnotificationcount > 0 && (
                        <small className="notify-count">
                          {ticketNotification.ticketnotificationcount > 9
                            ? "9+"
                            : ticketNotification.ticketnotificationcount}
                        </small>
                      )}

                      <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </Link>
                  :
                  <NavLink
                    to={("/", "/" + tab.refLink)}
                    className={
                      tab.refLink === getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      {tab.id === 5 && ticketNotification.ticketnotificationcount > 0 && (
                        <small className="notify-count">
                          {ticketNotification.ticketnotificationcount > 9
                            ? "9+"
                            : ticketNotification.ticketnotificationcount}
                        </small>
                      )}

                      <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </NavLink>
                  }
                </li>
              )
            }
            else if (tab.id === 9 && !checkMissingKey && reRegStatus && user.status !== "On Hold" && currentTabValue.length === 3 && isWithHeldStatus != "true") {
              return (
                <li
                  title="Your Re-Registrations"
                  key={tab.id}
                  onClick={() => isExamLaunched ? examPopup() : dispatch(selectTab({ tab: tab.id }))}
                >{isExamLaunched
                  ? <Link
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      {/* {(tab.id === 9) && (reRegistrationCount.reRegCount) > 0 && (
                        <small className="notify-count">
                          {(reRegistrationCount.reRegCount) > 9
                            ? "9+"
                            : (reRegistrationCount.reRegCount)}
                        </small>
                      )} */}
                      <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </Link>
                  :
                  <NavLink
                    to={("/", "/" + tab.refLink)}
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      {/* {(tab.id === 9) && (reRegistrationCount.reRegCount) > 0 && (
                        <small className="notify-count">
                          {(reRegistrationCount.reRegCount) > 9
                            ? "9+"
                            : (reRegistrationCount.reRegCount)}
                        </small>
                      )} */}
                      <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </NavLink>
                }
                </li>
              )
            }
            if (tab.id == 5 && !checkMissingKey && isWithHeldStatus != "true"  && !examRegStatus) {
              if(user.status === "On Hold"){
                return (
                  <li
                    title="Log and track your tickets with the AIE support team"
                    key={tab.id}
                    //className={tab.id == tabId ? "active" : ""}
  
                    onClick={() => isExamLaunched ? examPopup() : dispatch(selectTab({ tab: tab.id }))}
                  >{isExamLaunched
                    ? <Link
                      // style={{ backgroundColor: tab.refLink == getMenuItem(currentTabValue[1]) ? (brandConfig.configuration && brandConfig.configuration.topbar_color ? brandConfig.configuration.topbar_color : '#467FCF') : "" }}
                      className={
                        tab.refLink == getMenuItem(currentTabValue[1])
                          ? "active"
                          : ""
                      }
                    >
                      <span className="nav-icon">
                        {ticketNotification.ticketnotificationcount > 0 && (
                          <small className="notify-count">
                            {ticketNotification.ticketnotificationcount > 9
                              ? "9+"
                              : ticketNotification.ticketnotificationcount}
                          </small>
                        )}
  
                        <i className={"fal " + tab.class}></i>
                      </span>
                      {tab.name}
                    </Link>
                    :
                    <NavLink
                      to={("/", "/" + tab.refLink)}
                      // style={{ backgroundColor: tab.refLink == getMenuItem(currentTabValue[1]) ? (brandConfig.configuration && brandConfig.configuration.topbar_color ? brandConfig.configuration.topbar_color : '#467FCF') : "" }}
                      className={
                        tab.refLink == getMenuItem(currentTabValue[1])
                          ? "active"
                          : ""
                      }
                    >
                      <span className="nav-icon">
                        {ticketNotification.ticketnotificationcount > 0 && (
                          <small className="notify-count">
                            {ticketNotification.ticketnotificationcount > 9
                              ? "9+"
                              : ticketNotification.ticketnotificationcount}
                          </small>
                        )}
  
                        <i className={"fal " + tab.class}></i>
                      </span>
                      {tab.name}
                    </NavLink>
                  }
                  </li>
  
                );
              } else if (!reRegStatus && isWithHeldStatus != "true"){
                return (
                  <li
                    title="Log and track your tickets with the AIE support team"
                    key={tab.id}
                    //className={tab.id == tabId ? "active" : ""}

                    onClick={() => isExamLaunched ? examPopup() : dispatch(selectTab({ tab: tab.id }))}
                  >{isExamLaunched
                    ? <Link
                      // style={{ backgroundColor: tab.refLink == getMenuItem(currentTabValue[1]) ? (brandConfig.configuration && brandConfig.configuration.topbar_color ? brandConfig.configuration.topbar_color : '#467FCF') : "" }}
                      className={
                        tab.refLink == getMenuItem(currentTabValue[1])
                          ? "active"
                          : ""
                      }
                    >
                      <span className="nav-icon">
                        {ticketNotification.ticketnotificationcount > 0 && (
                          <small className="notify-count">
                            {ticketNotification.ticketnotificationcount > 9
                              ? "9+"
                              : ticketNotification.ticketnotificationcount}
                          </small>
                        )}

                        <i className={"fal " + tab.class}></i>
                      </span>
                      {tab.name}
                    </Link>
                    :
                    <NavLink
                      to={("/", "/" + tab.refLink)}
                      // style={{ backgroundColor: tab.refLink == getMenuItem(currentTabValue[1]) ? (brandConfig.configuration && brandConfig.configuration.topbar_color ? brandConfig.configuration.topbar_color : '#467FCF') : "" }}
                      className={
                        tab.refLink == getMenuItem(currentTabValue[1])
                          ? "active"
                          : ""
                      }
                    >
                      <span className="nav-icon">
                        {ticketNotification.ticketnotificationcount > 0 && (
                          <small className="notify-count">
                            {ticketNotification.ticketnotificationcount > 9
                              ? "9+"
                              : ticketNotification.ticketnotificationcount}
                          </small>
                        )}

                        <i className={"fal " + tab.class}></i>
                      </span>
                      {tab.name}
                    </NavLink>
                  }
                  </li>

                );
              }
            }
            else if (tab.id == 0 && user.status == "On Hold" && !checkMissingKey && isWithHeldStatus != "true") {
              return (
                <li
                  title={tab.id == 0 ? "Student Portal Home" : "Log and track your tickets with the AIE support team"}
                  key={tab.id}
                  //className={tab.id == tabId ? "active" : ""}

                  onClick={() => isExamLaunched ? examPopup() : dispatch(selectTab({ tab: tab.id }))}
                >{isExamLaunched
                  ? <Link
                    // style={{ backgroundColor: tab.refLink == getMenuItem(currentTabValue[1]) ? (brandConfig.configuration && brandConfig.configuration.topbar_color ? brandConfig.configuration.topbar_color : '#467FCF') : "" }}
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      {tab.id == 5 && ticketNotification.ticketnotificationcount > 0 && (
                        <small className="notify-count">
                          {ticketNotification.ticketnotificationcount > 9
                            ? "9+"
                            : ticketNotification.ticketnotificationcount}
                        </small>
                      )}

                      <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </Link>
                  :
                  <NavLink
                    to={("/", "/" + tab.refLink)}
                    // style={{ backgroundColor: tab.refLink == getMenuItem(currentTabValue[1]) ? (brandConfig.configuration && brandConfig.configuration.topbar_color ? brandConfig.configuration.topbar_color : '#467FCF') : "" }}
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      {tab.id == 5 && ticketNotification.ticketnotificationcount > 0 && (
                        <small className="notify-count">
                          {ticketNotification.ticketnotificationcount > 9
                            ? "9+"
                            : ticketNotification.ticketnotificationcount}
                        </small>
                      )}

                      <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </NavLink>
                }
                </li>

              );
            } 
            else if (tab.id == 12 && checkMissingKey && !reRegStatus && isWithHeldStatus != "true"  && !examRegStatus) {
              return (
                <li
                  title={tab.id == 12 ? "Student Portal Home" : ""}
                  key={tab.id}
                  //className={tab.id == tabId ? "active" : ""}

                  onClick={() => isExamLaunched ? examPopup() : dispatch(selectTab({ tab: tab.id }))}
                >{isExamLaunched
                  ? <Link
                    // style={{ backgroundColor: tab.refLink == getMenuItem(currentTabValue[1]) ? (brandConfig.configuration && brandConfig.configuration.topbar_color ? brandConfig.configuration.topbar_color : '#467FCF') : "" }}
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                  <span className="nav-icon"> <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </Link>
                  :
                  <NavLink
                    to={("/", "/" + tab.refLink)}
                    // style={{ backgroundColor: tab.refLink == getMenuItem(currentTabValue[1]) ? (brandConfig.configuration && brandConfig.configuration.topbar_color ? brandConfig.configuration.topbar_color : '#467FCF') : "" }}
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                  <span className="nav-icon"> <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </NavLink>
                }
                </li>
              );
            } else if (tab.id == 14 && !checkMissingKey && !reRegStatus && isWithHeldStatus == "true"  && !examRegStatus) {
              return (
                <li
                  title={tab.id == 14 ? "Student Portal Home" : ""}
                  key={tab.id}
                  //className={tab.id == tabId ? "active" : ""}

                  onClick={() => isExamLaunched ? examPopup() : dispatch(selectTab({ tab: tab.id }))}
                >{isExamLaunched
                  ? <Link
                    // style={{ backgroundColor: tab.refLink == getMenuItem(currentTabValue[1]) ? (brandConfig.configuration && brandConfig.configuration.topbar_color ? brandConfig.configuration.topbar_color : '#467FCF') : "" }}
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                  <span className="nav-icon"> <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </Link>
                  :
                  <NavLink
                    to={("/", "/" + tab.refLink)}
                    // style={{ backgroundColor: tab.refLink == getMenuItem(currentTabValue[1]) ? (brandConfig.configuration && brandConfig.configuration.topbar_color ? brandConfig.configuration.topbar_color : '#467FCF') : "" }}
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                  <span className="nav-icon"> <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </NavLink>
                }
                </li>
              );
            } 
            else if (
              tab.id == 6 &&
              user.status != "On Hold" 
              && !checkMissingKey
              && !reRegStatus && isWithHeldStatus != "true" && !examRegStatus
            ) {
              return (
                <li
                  title="Get important information of direct interest to you and your studies"
                  key={tab.id}
                  //className={tab.id == tabId ? "active" : ""}

                  onClick={() => isExamLaunched ? examPopup() : dispatch(selectTab({ tab: tab.id }))}
                >{isExamLaunched
                  ? <Link
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      {notificationcount.notificationcount > 0 && (
                        <small className="notify-count">
                          {notificationcount.notificationcount > 9
                            ? "9+"
                            : notificationcount.notificationcount}
                        </small>
                      )}
                      <i className={"fal " + tab.class}>
                      </i>
                    </span>
                    {tab.name}
                  </Link>
                  :
                  <NavLink
                    to={("/", "/" + tab.refLink)}
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      {notificationcount.notificationcount > 0 && (
                        <small className="notify-count">
                          {notificationcount.notificationcount > 9
                            ? "9+"
                            : notificationcount.notificationcount}
                        </small>
                      )}
                      <i className={"fal " + tab.class}>
                      </i>
                    </span>
                    {tab.name}
                  </NavLink>
                }
                </li>
              );
            } else if (
              tab.id < 8 && tab.id != 0 &&
              tab.id != 6 &&
              user.status != "On Hold" 
              && !checkMissingKey
              && !reRegStatus && isWithHeldStatus != "true" && !examRegStatus
            ) {
              return (
                <li
                  title={tab.id == 1 ? "Student Portal Home" : tab.id == 2 ? "Always keep your student profile up to date" : tab.id == 4 ? "All your important course information for easy study" : null}
                  key={tab.id}
                  //className={tab.id == tabId ? "active" : ""}

                  onClick={() => isExamLaunched ? examPopup() : dispatch(selectTab({ tab: tab.id }))}
                >{isExamLaunched
                  ? <Link
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      {(tab.id == 4) && (chatNotification.chatnotificationcount.newsRoomUnreadMessageCount - count) > 0 && (
                        <small className="notify-count">
                          {(chatNotification.chatnotificationcount.newsRoomUnreadMessageCount - count) > 9
                            ? "9+"
                            : (chatNotification.chatnotificationcount.newsRoomUnreadMessageCount - count)}
                        </small>
                      )}

                      <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </Link>
                  :
                  <NavLink
                    to={("/", "/" + tab.refLink)}
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      {(tab.id == 4) && (chatNotification.chatnotificationcount.newsRoomUnreadMessageCount - count) > 0 && (
                        <small className="notify-count">
                          {(chatNotification.chatnotificationcount.newsRoomUnreadMessageCount - count) > 9
                            ? "9+"
                            : (chatNotification.chatnotificationcount.newsRoomUnreadMessageCount - count)}
                        </small>
                      )}

                      <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </NavLink>
                }
                </li>
              );
            } else if (
              (tab.id == 8 || tab.id == 9) &&
              user.status != "On Hold" 
              && !checkMissingKey && isWithHeldStatus != "true"
            ) {
              if (tab.id == 8  && (examReg == "true" || examReg == true) && (examRegStatus || (!reRegStatus && !examRegStatus))) {
                return (
                  <li
                    title="Your Exam Registrations"
                    key={tab.id}
                    //className={tab.id == tabId ? "active" : ""}

                    onClick={() => isExamLaunched ? examPopup() : dispatch(selectTab({ tab: tab.id }))}
                  >{isExamLaunched
                    ? <Link
                      className={
                        tab.refLink == getMenuItem(currentTabValue[1])
                          ? "active"
                          : ""
                      }
                    >
                      <span className="nav-icon">
                        <i className={"fal " + tab.class}></i>
                      </span>
                      {tab.name}
                    </Link>
                  :
                    <NavLink
                      to={("/", "/" + tab.refLink)}
                      className={
                        tab.refLink == getMenuItem(currentTabValue[1])
                          ? "active"
                          : ""
                      }
                    >
                      <span className="nav-icon">
                        <i className={"fal " + tab.class}></i>
                      </span>
                      {tab.name}
                    </NavLink>
                  }
                  </li>
                );
              } else if (
                tab.id == 9 &&
                user.status != "On Hold" 
                && !checkMissingKey && isWithHeldStatus != "true" && (reReg == true || reReg == "true" ) &&  ((reRegStatus && !ArticulationStatus) || (!reRegStatus && !examRegStatus))
              ) {
                //
                return (
                  <li
                    title="Your Re-Registrations"
                    key={tab.id}
                    //className={tab.id == tabId ? "active" : ""}

                    onClick={() => isExamLaunched ? examPopup() : dispatch(selectTab({ tab: tab.id }))}
                  >{isExamLaunched
                    ? <Link
                      className={
                        tab.refLink == getMenuItem(currentTabValue[1])
                          ? "active"
                          : ""
                      }
                    >
                      <span className="nav-icon">
                      {/* {(tab.id === 9) && (reRegistrationCount.reRegCount) > 0 && (
                        <small className="notify-count">
                          {(reRegistrationCount.reRegCount) > 9
                            ? "9+"
                            : (reRegistrationCount.reRegCount)}
                        </small>
                      )} */}
                        <i className={"fal " + tab.class}></i>
                      </span>
                      {tab.name}
                    </Link>
                    :
                    <NavLink
                      to={("/", "/" + tab.refLink)}
                      className={
                        tab.refLink == getMenuItem(currentTabValue[1])
                          ? "active"
                          : ""
                      }
                    >
                      <span className="nav-icon">
                      {(tab.id === 9) && (reRegistrationCount.reRegCount) > 0 && (
                        <small className="notify-count">
                          {(reRegistrationCount.reRegCount) > 9
                            ? "9+"
                            : (reRegistrationCount.reRegCount)}
                        </small>
                      )}
                        <i className={"fal " + tab.class}></i>
                      </span>
                      {tab.name}
                    </NavLink>
                  }
                  </li>
                );
              }
            } else if (
              tab.id == 10 &&
              user.status != "On Hold" 
              && !checkMissingKey
              && !reRegStatus && isWithHeldStatus != "true" && !examRegStatus
            ) {
              return (
                <li
                  title="All your important dates in one location"
                  key={tab.id}
                  onClick={() => isExamLaunched ? examPopup() : dispatch(selectTab({ tab: tab.id }))}
                >{isExamLaunched
                  ? <Link
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </Link>
                  :
                  <NavLink
                    to={("/", "/" + tab.refLink)}
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </NavLink>
                }
                </li>
              );
            } else if (
              tab.id == 11 &&
              user.status != "On Hold" 
              && !checkMissingKey
              && !reRegStatus && isWithHeldStatus != "true" && !examRegStatus
            ) {
              return (
                <li
                  title="Access academic resources, policies, library and how-to guides here"
                  key={tab.id}
                  onClick={() => isExamLaunched ? examPopup() : dispatch(selectTab({ tab: tab.id }))}
                >{isExamLaunched
                  ? <Link
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </Link>
                  :
                  <NavLink
                    to={("/", "/" + tab.refLink)}
                    className={
                      tab.refLink == getMenuItem(currentTabValue[1])
                        ? "active"
                        : ""
                    }
                  >
                    <span className="nav-icon">
                      <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                  </NavLink>
                }
                </li>
              );
            }
          })}
        </ul>
      </div>
    </>
  );
};

export default SideBar;
