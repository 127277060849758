import React, { useState, useEffect } from "react";
import ShortNameBox from "./ShortNameBox";
import {subjectDuration,getSubjectStatus, formatDateRange, calculateTotalMonths} from "../../utils/commonFunction.js";
import moment from "moment";
import Str from "../common/Str";
import MentoredSubject from "./MentoredSubject";
import { ProgramIcons } from "./CommonCourseComponents";
import { loadGraphModel } from "@tensorflow/tfjs";
import { useHistory } from "react-router-dom";
const stc = require('string-to-color');

const SubjectHeadBox = (props) => {
  const { total_documents = 0, total_assessment = 0, totalLearningMaterialPercentage = 0, total_videos = 0, total_quizs = 0, total_links = 0 } = props?.statisticsData || {};  const row=props.content;
  const [status, setStatus] = useState(null);
  const [statusIcon, setStatusIcon] = useState(null);
  const [data, setData] = useState(null);
  const history = useHistory()

  useEffect(() => {    
    let icon = getSubjectStatus(row.subjectStatus);
    setStatus(row.subjectStatus);
    setStatusIcon(icon);
  }, [props.content]);
  const dateFormat = "Do MMM YY";
  return (
    <>
      <div className="lecture-detail-top d-flex justify-content-between  shift-move">
        <div className="lecture-detail-top-left">
          <div className="lecture-bx-info  d-flex">
            <ShortNameBox qualification={{ course: row.name }} paramColor={stc(row.name)}></ShortNameBox>
            {/* <div className="icon honours-icon"><ProgramIcons icon={"honours"}/></div> */}
            <div className="lecture-bx-info-rgt">
              <h3 title={row.name}>{row.name}</h3>
              <div className="course-statistics-count">
                {props.isAdmin != 1 ? (
                  (() => {
                    if (row.type === "selfplaced" && row.subject_type === "shortcourse") {
                      switch (row.student_intake_status) {
                        case 0:
                          return (
                            <p title={formatDateRange(row?.start, row?.end)}>
                              <i className="fal fa-clock"></i>
                              {row?.subject_duration + " months"}
                            </p>
                          );
                        case 1:
                        case 4:
                          return row?.showTargetStart && row?.showTargetEnd ? (
                            (() => {
                              const startDate = moment(row.showTargetStart, dateFormat).unix();
                              const endDate = moment(row.showTargetEnd, dateFormat).unix();
                              return (
                                <p title={formatDateRange(startDate, endDate)}>
                                   <i className="fal fa-clock"></i>
                                  {formatDateRange(startDate, endDate)}
                                </p>
                              );
                            })()
                          ) : (
                            "Invalid Date"
                          );                        
                        case 2: 
                        case 3: 
                          const formattedDate = formatDateRange(moment(row.showTargetStart, dateFormat).unix(), moment(row?.showCompletion, dateFormat).unix())
                          return row?.showTargetStart && row?.showCompletion ? (
                            <p title={formattedDate}>
                              <i className="fal fa-clock"></i>
                              {formattedDate}
                             </p>  
                          ) : (
                            "Invalid Date"
                          );                                             
                          default:
                            return (
                              <p title={formatDateRange(moment(row.showStart, dateFormat).unix() , moment(row.showEnd, dateFormat).unix()) }>
                              <i className="fal fa-clock"></i>
                              {formatDateRange(moment(row.showStart, dateFormat).unix() , moment(row.showEnd, dateFormat).unix()) }
                            </p>
                            )
                      }
                    } else if (row.subject_type === "mentored") {

                      switch (row.student_intake_status) {
                        case 0: 
                        return (
                          <p title={formatDateRange(moment(row.showStart, dateFormat).unix() , moment(row.showEnd, dateFormat).unix()) }>
                            <i className="fal fa-clock"></i>
                            {formatDateRange(moment(row.showStart, dateFormat).unix() , moment(row.showEnd, dateFormat).unix()) }
                          </p>
                        )  
                        case 1: 
                        case 4: 
                        return (
                          <p title={formatDateRange(moment(row.showTargetStart, dateFormat).unix() , moment(row.showTargetEnd, dateFormat).unix()) }>
                            <i className="fal fa-clock"></i>
                            {formatDateRange(moment(row.showTargetStart, dateFormat).unix() , moment(row.showTargetEnd, dateFormat).unix()) }
                          </p>
                        )  
                        case 2: 
                        case 3:
                          return (
                            <p title={formatDateRange(moment(row.showTargetStart, dateFormat).unix() , moment(row.showCompletion, dateFormat).unix()) }>
                              <i className="fal fa-clock"></i>
                              {formatDateRange(moment(row.showTargetStart, dateFormat).unix() , moment(row.showCompletion, dateFormat).unix()) }
                            </p>
                          )     
                        default: 
                        return (
                          <p title={formatDateRange(moment(row.showStart, dateFormat).unix() , moment(row.showEnd, dateFormat).unix()) }>
                            <i className="fal fa-clock"></i>
                            {formatDateRange(moment(row.showStart, dateFormat).unix() , moment(row.showEnd, dateFormat).unix()) }
                          </p>
                        )     
                      }
                    } else {
                      return row?.start && row?.end ? (
                        <p title={formatDateRange(row.start, row.end)}>
                          <i className="fal fa-calendar-alt"></i>
                          {formatDateRange(row.start, row.end)}
                        </p>
                      ) : (
                        "Invalid Date"
                      );
                    }
                  })()
                ) : (

                  <p title={formatDateRange(row?.start, row?.end)}>
                    <i className="fal fa-calendar-alt"></i>
                    {formatDateRange(row?.start, row?.end)}
                  </p>
                )}
                <ul className="num-info">
                  {row?.hide_learning_material_count === 0 && (
                    <>
                      {total_videos > 0 ? <li title="Videos">
                        <i className="fal fa-video"></i>
                        <span>{total_videos}</span>
                      </li> : null}
                      {total_quizs > 0 ? <li title="Quizzes">
                        <i className="fal fa-clipboard-list"></i>
                        <span>{total_quizs}</span>
                      </li> : null}
                      {total_documents > 0 ? <li title="Files">
                        <i className="fal fa-file"></i>
                        <span>{total_documents || 0}</span>
                      </li> : null}
                    </>
                  )}
                  {row?.hide_assessments_count === 0 && total_assessment > 0 && (
                    <li title="Assessments">
                      <i className="fal fa-ballot-check"></i>
                      <span>{total_assessment || 0}</span>
                    </li>
                  )}
                </ul>
              </div>

            </div>
          </div>
        </div>
        <div className={`lecture-detail-top-right d-flex ${history.location.pathname.includes("shortCourse") ? "mentored__short__course" : "mentored__course"}`}>
          <ul className="lec-dtl-rgt-ul m-0 d-flex list-unstyled align-items-center flex-wrap">
            {/* <li>
              <div className="msg-icon">
                <i className="fal fa-comment-alt-lines"></i>
              </div>
            </li>
            <li>
              <div className="your-complete-date d-flex bg-white">
                <div className="yr-com-lft">
                  {row.type === "selfplaced" && row.subject_type === "shortcourse" ? <i className="fal fa-clock"></i> : <i className="fal fa-calendar-alt"></i>}
                </div>
                {/* <div className="yr-com-rgt">
                  <small>Your Completion Date</small>
                  <p className="m-0">
                    {showStart} To {showEnd} {subjectDuration(showStart,showEnd)}
                  </p>
                </div> 
                {props.isAdmin!=1 ? 
                  (() => {
                      if(row.type === "selfplaced" && row.subject_type === "shortcourse"){
                        switch (row.student_intake_status){
                          case 0:   
                          return <div className="yr-com-rgt">
                          <small>Course Duration</small>
                          <p className="m-0">{row?.subject_duration + " months"}</p>
                          </div>;
                          case 1: 
                          case 4: 
                          return <div className="yr-com-rgt">
                          <small>Course Duration</small>
                          <p className="m-0">{moment(row.showStart, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart, row.showEnd)}</p>
                          </div>;
                          case 2:
                          return <div className="yr-com-rgt">
                          <small>Course Duration</small>
                          <p className="m-0">{moment(row.showTargetStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showCompletion,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showTargetStart,row.showCompletion)}</p>
                          </div>;   
                          case 3:   
                          return <div className="yr-com-rgt">
                          <small>Your Completion Date</small>
                          <p className="m-0">{moment.unix(row.completed_date).format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showTargetStart, row.showCompletion)}</p>
                          </div>;
                          default: 
                          return <div className="yr-com-rgt">
                          <small>Recommended Date</small>
                          <p className="m-0">{moment(row.showStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart,row.showEnd)}</p>
                          </div>;
                        }

                      }else if(row.subject_type=="mentored"){
                        switch (row.student_intake_status) {
                          case 0:   
                          return <div className="yr-com-rgt">
                          <small>Recommended Date</small>
                          <p className="m-0">{moment(row.showStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart,row.showEnd)}</p>
                          </div>;
                          case 1: 
                          case 4: 
                          return <div className="yr-com-rgt">
                          <small>Committed Date</small>
                          <p className="m-0">{moment(row.showTargetStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showTargetEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showTargetStart,row.showTargetEnd)}</p>
                          </div>;
                          case 2: 
                          case 3:   
                          return <div className="yr-com-rgt">
                          <small>Completion Date</small>
                          <p className="m-0">{moment(row.showTargetStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showCompletion,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showTargetStart,row.showCompletion)}</p>
                          </div>;
                          default: 
                          return <div className="yr-com-rgt">
                          <small>Recommended Date</small>
                          <p className="m-0">{moment(row.showStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart,row.showEnd)}</p>
                          </div>;
                      }
                      }else{
                       return <div className="yr-com-rgt">
                                <p className="m-0">{moment(row.showStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart,row.showEnd)}</p>
                              </div>
                      }
                      
                  })()
                  :(<div className="yr-com-rgt">
                      <p className="m-0">{moment(row.showStart,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showEnd,"Do MMM YY").format(Str.TABLE_DATE_FORMAT)} {subjectDuration(row.showStart,row.showEnd)}</p>
                  </div>)}
              </div>
            </li> */}
            <li className="subject-status-li subject__status  ">
              <img src={statusIcon} alt="Status" />              
              <p className="m-0">{status}</p>
            </li>
            
            {(props.isAdmin != 1 && (row?.subject_type=="shortcourse" || row.subject_type=="mentored")) ?             
              <MentoredSubject subjectContent={props.subjectContent} row={row} updateSubject={props.updateSubject} /> 
            : null}
          </ul>
        </div>
      </div>      
    </>
  );
};
export default SubjectHeadBox;