import React, { useEffect, useRef, useState } from 'react';
import { TrimText } from '../common/TrimText';
import { InitalRender } from '../common/Helper';
import { ColorRender } from '../ticket/StatusRender';
import { BASE_URL } from '../../utils/Constants';

const ProfileImage = ({ photo, name, status, category }) => {
  return (
    <>
        {photo ? (
            <img
                src={`${BASE_URL}/${photo.includes("/home/myaie/public_html/") ? photo?.replace("/home/myaie/public_html/", "") : photo}`}
                style={{borderRadius: "50%"}}
                // onError={({ currentTarget }) => {
                //     currentTarget.onerror = null;
                //     currentTarget.closest('.name-icon.cat-dark-red').append(InitalRender(name));
                //     currentTarget.remove();
                // }}
            />
        ) : (
            InitalRender(name)
        )}
        {status === "Offline" ? (
            <span className="portal-status-icon offline-u"></span>
        ) : (
            <span className="portal-status-icon online-u"></span>
        )}
    </>
  );
};

function ViewProfile({ user, cardParent, disabled = false, viewTimeTable, parentBox = false, popRef }) {
  const [isToggled, setIsToggled] = useState(false);
  const [popupPosition, setPopupPosition] = useState('right');
  const [isTabletScreen, setIsTabletScreen] = useState(false);
  const divRef = useRef(null);
  const moreViewRef = useRef(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px) and (max-width: 1024px)');
    const handleMediaChange = (e) => setIsTabletScreen(e.matches);
    setIsTabletScreen(mediaQuery.matches);
    mediaQuery.addEventListener('change', handleMediaChange);    
    return () => mediaQuery.removeEventListener('change', handleMediaChange);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const returncounter = (user) => {
    let counter = 0;
    const uniqueNames = new Set();
    if (user.prog_coach_name && user.prog_coach_name !== "") uniqueNames.add(user.prog_coach_name);
    // if (user.intake_coach_name && user.intake_coach_name !== "") uniqueNames.add(user.intake_coach_name);
    if (user.prog_lead_name && user.prog_lead_name !== "") uniqueNames.add(user.prog_lead_name);
    if ((user.prog_supp_name && user.prog_supp_name !== "") || 
        (user.supp_fullname && user.supp_fullname !== "")) {
        uniqueNames.add(user.prog_supp_name || user.supp_fullname);
    }
    return Number(uniqueNames.size - 1);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    if (disabled) return;
    
    setIsToggled(!isToggled);
    
    if (!moreViewRef.current || !popRef?.current) return;
    
    const moreViewRect = moreViewRef.current.getBoundingClientRect();
    const parentRect = popRef.current.getBoundingClientRect();
    const spaceOnRight = parentRect.right - moreViewRect.right;
    const popupWidth = 162;
    
    setPopupPosition(spaceOnRight >= popupWidth ? 'right' : 'left');
  };

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setIsToggled(false);
    }
  };

  if (!user?.prog_lead_name) return null;

  return (
    <div onClick={(e) => e.preventDefault()}> 
      <div className="assigned-title-itm lecturer-profile support-team-status-div" onClick={(e) => e.preventDefault()}>
        {console.log("user")}
        <span 
          className={`name-icon cat-dark-red ${user?.prog_lead_photo || user?.lead_photo ? 'transparentBg' : ColorRender(user?.category)} user-drop-down-img`}
          title={user?.prog_lead_name}
          style={{ fontSize: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <ProfileImage 
            photo={user?.lead_photo}
            name={user.prog_lead_name}
            status={user?.prog_lead_status}
            category={user?.category}
          />
        </span>

        <div className="profile__user__name" title={user?.prog_lead_name}>
          {parentBox 
            ? (user?.prog_lead_name || "NA")
            : TrimText(user?.prog_lead_name || "NA", viewTimeTable ? 12 : 16)}
        </div>

        {(user?.supp_fullname || user?.coach_name) && (returncounter(user) > 0) && (
          <div className="position-relative">
            <span ref={moreViewRef} className="more-view" id="view-team" onClick={handleButtonClick}>
              +{returncounter(user)}
            </span>
            
            {isToggled && (
              <div 
                ref={divRef} 
                className={`support-team-profile qualification-user-card ${!cardParent ? "parent-team-profile" : ""}`}
                style={{
                  maxWidth: user?.prog_lead_name.length > 15 ? "140px" : "162px",
                  height: "auto",
                  position: 'absolute',
                  ...(isTabletScreen ? {
                    [popupPosition === 'right' ? 'left' : 'right']: '100%',
                    [popupPosition === 'right' ? 'right' : 'left']: 'unset'
                  } : {})
                }}
              >
                <div className="leacture-supportor-profile">
                  <div className="border__bottom__div">
                    <p className="team">{cardParent ? "Lecturer" : "Programme Coordinator"}</p>
                    <article>
                      <figure className={`${user?.lead_photo ? 'transparentBg' : ColorRender(user?.category)} team-detail`} title={user?.prog_lead_name}>
                        {/* <span
                            className={`name-icon cat-dark-red ${user?.prog_lead_photo ? 'transparentBg' : ColorRender(user?.category)} user-drop-down-img`}
                            title={user?.prog_lead_name}
                            style={{ fontSize: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}
                        > */}
                            <ProfileImage
                                photo={user?.lead_photo}
                                name={user?.prog_lead_name}
                                status={user?.prog_lead_status}
                                category={user?.category}
                            />
                        {/* </span> */}
                      </figure>
                      <figcaption>
                        <h4 title={user?.prog_lead_name}>
                          {TrimText(user?.prog_lead_name || "NA", user?.prog_lead_name?.length > 15 ? 12 : 15)}
                        </h4>
                      </figcaption>
                    </article>
                  </div>

                  {cardParent && user?.prog_coach_name && (
                    <div className="border__bottom__div">
                      <p className="team">{cardParent ? "Programme Coordinator" : "Programme Support"}</p>
                      <article>
                        <figure className={`${user?.coach_photo ? 'transparentBg' : ColorRender(user?.category)} team-detail`} title={user?.prog_coach_name}>
                        {/* <span
                            className={`name-icon cat-dark-red ${user?.prog_lead_photo ? 'transparentBg' : ColorRender(user?.category)} user-drop-down-img`}
                            title={user?.prog_lead_name}
                            style={{ fontSize: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}
                        > */}
                          <ProfileImage 
                            photo={user?.coach_photo}
                            name={user?.prog_coach_name}
                            status={user?.prog_coach_status}
                            category={user?.category}
                          />
                          {/* </span> */}
                        </figure>
                        <figcaption>
                          <h4 title={user?.prog_coach_name}>
                            {TrimText(user?.prog_coach_name || "NA", user?.prog_coach_name?.length > 15 ? 12 : 15)}
                          </h4>
                        </figcaption>
                      </article>
                    </div>
                  )}

                  {user?.supp_fullname && (
                    <div className="border__bottom__div">
                      <p className="team">Programme Support</p>
                      <article>
                        <figure className={`${user?.supp_photo ? 'transparentBg' : ColorRender(user?.category)} team-detail`} title={user?.supp_fullname}>
                            {/* <span
                                className={`name-icon cat-dark-red ${user?.prog_lead_photo ? 'transparentBg' : ColorRender(user?.category)} user-drop-down-img`}
                                title={user?.prog_lead_name}
                                style={{ fontSize: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}
                            > */}
                                <ProfileImage
                                    photo={user?.supp_photo}
                                    name={user?.supp_fullname}
                                    status={user?.supp_activity_status}
                                    category={user?.category}
                                />
                            {/* </span> */}
                        </figure>
                        <figcaption>
                          <h4 title={user?.supp_fullname}>
                            {TrimText(user?.supp_fullname || "NA", user?.prog_lead_name?.length > 15 ? 12 : 15)}
                          </h4>
                        </figcaption>
                      </article>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewProfile;
