import React from "react";
import { BASE_URL, zipURL } from "../../utils/Constants";
import { FetchFileIcon } from "../../utils/commonFunction";
import Swal from "sweetalert2";
import { download } from "../../utils/FileDownload";

export const RenderAttachList = (props) => {
    const downloadFile = (filePath, fileName) => {
        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });
        
        let fileURL = filePath;

        // if(!fileURL.includes("chat.myaie.ac")){
        //     fileURL = API_BASE.replace("/api/v1", "") + "/" + row.attachment.replace("/home/myaie/api/public_html/", "").replace("/home/myaie/public_html/", "").replace("public/", "")
        // }
        download(fileURL, fileName)
        

    }
    const openFile = (filePath) => {
        if (!filePath?.includes(zipURL)) {
            filePath = filePath?.replace(zipURL, "")
        }
        //add https:// to start of filepath if it is not there
        if (!filePath.startsWith("http") && !filePath.startsWith("https")) {
            filePath = BASE_URL + (filePath.startsWith("/")
                ? filePath
                : "/" + filePath);
        }
        window.open(filePath, "_blank"); // Open file in a new tab
      };
    return (<div className="col-md-4 mb-4">
        <div className="uploded-files-item feed-pad">
            <div className="uploded-files-item-txt curser"
                data-toggle="tooltip"
                data-placement="right"
                onClick={() => openFile(props.filePath, props.fileName)}
                title={props.fileName}/*onClick={() => downloadFile(props.filePath, props.fileName)}*/>
                <figure>
                    {/* <i className="fas fa-file"></i> */}
                    {props?.fileName !== undefined && props?.fileName ? <i className={FetchFileIcon(props?.fileName)}></i> : null}
                </figure>
                <figcaption >
                    <h4 className="oneline-ellipsis">{props.fileName}</h4>
                    <p>{props.size}</p>
                </figcaption>
            </div>

            {props.showClose ?
                <button onClick={() => { props.removeF(props) }} type="button" className="btn file-remove-btn"><i className="fal fa-times hold-show"></i></button>
                :
                <a onClick={(e) => {e.stopPropagation(); downloadFile(props?.filePath.replaceAll(" ","%20"),props?.fileName) }} className="btn file-remove-btn rm-box-shadow download-icon"><i className="fal fa-download download-icon"></i></a>
                // <button onClick={() => downloadFile(zipURL + props?.filePath.replaceAll(" ","%20"),props?.fileName) }><i className="fal fa-download download-icon"></i></button>
            }
        </div>
    </div>
    )
}