import React, { useState, useEffect } from "react";
import ShortNameBox from "../course/ShortNameBox";
import {subjectDuration,getSubjectStatus, formatDateRange, calculateTotalMonths} from "../../utils/commonFunction";
import moment from "moment";
import Str from "../common/Str";
import { ProgramIcons } from "../course/CommonCourseComponents";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
const stc = require('string-to-color');

const ProgramHeadBox = (props) => {
  const location = useLocation();
 const row=props.content;
  const [status, setStatus] = useState(null);
  const [statusIcon, setStatusIcon] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {    
    let icon = getSubjectStatus(row.subjectStatus);
    setStatus(row.subjectStatus);
    setStatusIcon(icon);
  }, [props.qualification]);

  return (
    <>
      <div className="lecture-detail-top d-flex justify-content-between  shift-move">
        <div className="lecture-detail-top-left">
          <div className="lecture-bx-info  d-flex">
            {/* <ShortNameBox qualification={{ course: props.content?.course }} paramColor={stc(row.name)}></ShortNameBox> */}
            <div className={`icon ${row.program_type_name ? row.program_type_name?.toString()?.replace("’s", "")?.replace(" ", "") : "null"}-icon`} title={row.program_type_name ? row.program_type_name : "NA"}><ProgramIcons degree={row.program_type_name ? row.program_type_name : "NA"}/></div>
            <div className="lecture-bx-info-rgt">
              <h3 title={row.course}>{row.course}</h3>
              <div className="course-statistics-count">
              {props.isAdmin != 1 ? (
                  (() => {
                    if (row.type === "selfplaced" && row.subject_type === "shortcourse") {
                      switch (row.student_intake_status) {
                        case 0:
                          return (
                            <p title={formatDateRange(row?.start, row?.end)}>
                              <i className="fal fa-clock"></i>
                              {row?.subject_duration + " months"}
                            </p>
                          );
                        case 2:
                        case 1:
                        case 4:   
                          return row?.showStart && row?.showEnd ? (
                            <p title={calculateTotalMonths(row.showStart, row.showEnd)}>
                              <i className="fal fa-clock"></i>
                              {calculateTotalMonths(row.showStart, row.showEnd) + " months"}
                            </p>
                          ) : (
                            "Invalid Date"
                          );                                             
                        case 3:
                        default:
                          return row?.start && row?.end ? (
                            <p title={formatDateRange(row.start, row.end)}>
                              <i className="fal fa-calendar-alt"></i>
                              {formatDateRange(row.start, row.end)}
                            </p>
                          ) : (
                            "Invalid Date"
                          );
                      }
                    } else if (row.subject_type === "mentored") {
                      return row?.start && row?.end ? (
                        <p title={formatDateRange(row.start, row.end)}>
                          <i className="fal fa-calendar-alt"></i>
                          {formatDateRange(row.start, row.end)}
                        </p>
                      ) : (
                        "Invalid Date"
                      );
                    } else {
                      return row?.start && row?.end ? (
                        <p title={formatDateRange(row.start, row.end)}>
                          <i className="fal fa-calendar-alt"></i>
                          {formatDateRange(row.start, row.end)}
                        </p>
                      ) : (
                        "Invalid Date"
                      );
                    }
                  })()
                ) : (
                  <p title={formatDateRange(row?.start, row?.end)}>
                    <i className="fal fa-calendar-alt"></i>
                    {formatDateRange(row?.start, row?.end)}
                  </p>
                )}
                <ul className="num-info">
                  {/* Uncomment and modify these lines if necessary */}
                  {/* {row?.hide_learning_material_count === 0 && (
                    <>
                      <li title="Videos">
                        <i className="fal fa-video"></i>
                        <span>{props?.statisticsData?.total_videos || "0"}</span>
                      </li>
                      <li title="Quizzes">
                        <i className="fal fa-clipboard-list"></i>
                        <span>{props?.statisticsData?.total_quizs || "0"}</span>
                      </li>
                      <li title="Files">
                        <i className="fal fa-file"></i>
                        <span>{props?.statisticsData?.total_documents || "0"}</span>
                      </li>
                    </>
                  )}
                  {row?.hide_assessments_count === 0 && (
                    <li title="Assessments">
                      <i className="fal fa-ballot-check"></i>
                      <span>{props?.statisticsData?.total_assessment || "0"}</span>
                    </li>
                  )} */}
                </ul>
              </div>

            </div>
          </div>
        </div>
        <div className={`lecture-detail-top-right d-flex ${!location.pathname.includes("shortCourse") ? "qualification__head__box" : null}`}>
          <ul className="lec-dtl-rgt-ul m-0 d-flex list-unstyled align-items-center flex-wrap">
            <li className="subject-status-li" title={status}>
              <img src={statusIcon} alt="Status" />              
              <p className="m-0">{status}</p>
            </li>
          </ul>
        </div>
      </div>      
    </>
  );
};
export default ProgramHeadBox;