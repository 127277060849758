import { param } from "jquery";
import axiosInstance from "../utils/axios";

export const getAssembliesItem = async (value) => {
  return await axiosInstance().get(`/getLMAssembliesItem`, {
    params: { assembly: value },
  });
};

export const getAssembliesItemAdmin = async (value) => {
  return await axiosInstance().get(`/getAssembliesItemAdmin`, {
    params: { assembly: value },
  });
};

export const getAssembliesItemIDList = async (value) => {
  return await axiosInstance().get(`/getLMAssembliesItemIDs`, {
    params: { sid: value },
  });
};

export const updateVideoTimeService = async (
  assembly,
  watchedVdSec,
  updateSecond = true,
  duration = 0
) => {
  return await axiosInstance().get(`/updateVideoTime`, {
    params: {
      assembly: assembly,
      watchedVdSec: watchedVdSec,
      updateSecond: updateSecond,
      duration: duration,
    },
  });
};

export const updateWatchCountService = async (assembly) => {
  return await axiosInstance().get(`/updateWatchCount`, {
    params: {
      assembly: assembly,
    },
  });
};

export const getVideoTimeService = async (assembly) => {
  return await axiosInstance().get(`/getVideoTime`, {
    params: { assembly: assembly },
  });
};

export const GetLearningMaterial = async (values) => {
  return await axiosInstance().get("/getLearningMaterial", { params: values });
};

export const GetLearningMaterialAdmin = async (values) => {
  return await axiosInstance().get("/getLearningMaterialAdmin", { params: values });
};


export const getLMContentAssemblies = async (values) => {
  return await axiosInstance().get("/getLMContentAssemblies", { params: values });
};
export const getLearningMaterialAdmin = async (values) => {
  return await axiosInstance().get("/getLearningMaterialAdmin", { params: values });
};

export const fileTrackingService = async (value) => {
  return await axiosInstance().get(`/fileTracking`, {params: value});
};

export const AddNotesService = async (values) => {
  return await axiosInstance().post("/addStudentAssembliesNote", values);
};
export const getNotesAssembliesList = async (values) => {
  return await axiosInstance().get("/getAllStudentAssembliesNotes", { params: values });
};

export const deleteAssembliestNote = async (values) => {
  return await axiosInstance().post("/deleteStudentAssembliesNote", values);
};
export const updateAssembliesNote = async (values) => {
  return await axiosInstance().post("/updateStudentAssembliesNote",values);
};
export const getOneAssemblieNote = async (values) => {
  return await axiosInstance().get("/getStudentAssembliesNote", { params: values });
};
