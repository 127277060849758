import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { GetQuizAttemptsService } from "../../../services/QuizService";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";

export default function ViewQuiz(props) {
  const { quizId, setshow, setrId, rId, setlist,list } = props;
  // const [list, setlist] = useState([]);
  const [quiz, setquiz] = useState({});
  const [maximumLimit, setmaximumLimit] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    GetQuizAttemptsService({ quid: quizId })
      .then((res) => {
        setLoading(false);
        // console.log(res.data);
        setlist(res.data.data.attempts);
        setquiz(res.data.data.description);
        setmaximumLimit(res.data.data.maximumLimit);
      })
      .catch((err) => {
        setLoading(false);
        setlist([]);
      });
  }, []);

  const date = (value) => {
    const timestamp = new Date(value * 1000);
    return moment(timestamp).format("MM/DD/YYYY h:mm a");
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const secToMin = (value) => {
    var timestamp = value;
    var hours = Math.floor(timestamp / 60 / 60);
    var minutes = Math.floor(timestamp / 60) - hours * 60;
    var seconds = timestamp % 60;
    // hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    var formatted =
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0");
    return formatted;
  };
  // quiz result list data table 
  return (
    <>
      {/* <div class="main-action-btns text-right">
        <a
          onClick={() => setshow({ list: true })}
          class="btn btn-white-bordered  btn-back m-0"
        >
          <i class="fal fa-angle-left"></i>Back to Learning Material
        </a>
        // <a
        //   onClick={() => setshow({ attempt: true })}
        //   class="btn btn-white-bordered"
        // >
        //   <i class="fal fa-clipboard-list"></i>Attempted Quiz Result List
        // </a>
        <a
          onClick={() => {
            if (maximumLimit) {
              setshow({ desc: true });
            }
          }}
          class="btn btn-white-bordered"
          style={
            maximumLimit
              ? {
                  cursor: "pointer",
                }
              : {
                  cursor: "not-allowed",
                  backgroundColor: "#d7dee8",
                }
          }
          // style={{
          //   cursor: maximumLimit ? "pointer" : "not-allowed",
          // }}
        >
          <i class="fal fa-redo-alt fa-flip-horizontal"></i>Attempt Again
        </a>
      </div> */}
      <div className="card card-header-transparent">
        {/* <div className="card-header">{quiz.quiz_name}</div> */}
        {loading ? <SkeletonTicketList/> :<div className="card-body">
          <div className="table-responsive table__quize__desc">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>Attempt</th>
                  <th>Attempt Time</th>
                  <th>Score</th>
                  <th>Time Spent (Min)</th>
                  <th>Percentage (%)</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr>
                    <td>
                      <a
                        href="javascript:;"
                        onClick={() => {
                          setrId(item?.rid);
                          setshow({attempt: false,
                            review: true});
                        }}
                      >
                        {index + 1}. Attempted Quiz Result
                      </a>
                    </td>
                    <td>{date(item.start_time)}</td>
                    <td>{~~item.score_obtained}</td>
                    <td>{secToMin(item.total_time)}</td>
                    <td>{~~item.percentage_obtained + "%"}</td>
                    <td>{capitalizeFirstLetter(item.result_status)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>}
      </div>
    </>
  );
}
