import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import SocketConnection from "../../utils/SocketConnection";
import "../../assets/css/chat.css";
import ReactHtmlParser from "react-html-parser";
import { getRandomInt } from "../../utils/commonFunction";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ADMIN_PASSWORD } from "../../utils/Constants";
import ProgramHeadBox from "./ProgramHeadBox";
import { GetPostFeedMessagesPaginate, readAllCommentMessages,GetMyUnreadChatNotificationCount, GetCourseStatistics, GetCourseResourceDocumentsCount } from "../../services/QualificationService";
import SkeletonSubjectHeadBox from "../../loaders/SekletonSubjectHeadBox";
import { clearToggles, modalOpen, setIsExamLaunched, setUnreadChatNotificationCount } from "../../store/actions";
import SubjectGroupList from "./SubjectGroupList";
import SubjectHeadBox from "../course/SubjectHeadBox";

const ProgramContent = (props) => {
  // const {learning_material_percentage,assessment_percentage,complete_percentage}=props?.qualification.progress
  const { aid } = useParams()
  const dispatch = useDispatch();
  const {password} = useParams();
  const [tab, setTab] = useState("overview");
  // const [toggleScroll, setToggleScroll] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [statistics, setStatistics] = useState({
  //   learning_material_percentage:0,
  //   assessment_percentage:0,
  //   complete_percentage:0
  // });
  const [modalOpen, setModalOpen] = useState("")
  const scrollBottom = (behavior = "auto") => {
    const element = document.getElementById("content");
    if(element)
      element.scrollIntoView({ behavior: behavior, block: "end", inline: "nearest" });
  }
  // useEffect(()=>{
  //   if(password!=ADMIN_PASSWORD){
  //     GetCourseStatistics({sid : props.qualification?.id}).then((res)=>{
  //       if(res.status==200){
  //         setStatistics(res.data);
  //       }else{
  //         setStatistics(null)
  //       }
  //     }).catch((err)=>{
  //       // console.log(err)
  //     })
  //   }else{
  //     GetCourseResourceDocumentsCount({sid : props.subjectContent.subject.id}).then((res)=>{
  //       if(res.status==200){
  //         setStatistics(res.data);
  //       }else{
  //         setStatistics(null)
  //       }
  //     })
  //   }
  // },[props.qualification?.id])

  // useEffect(()=>{
  //   if(props.backToOverview>0){
  //     if(tab=="overview"){
  //         setTab("overview")
  //     }
  //     if(tab == "programme_gradebook"){
  //       setTab('programme_gradebook')
  //     }
  //   }
  // },[props.backToOverview])

  // let overview = ReactHtmlParser(props?.qualification?.overview); 
 
  let showShortCourse = props?.subjectContent?.subject?.subject_type === "shortcourse" && props?.subjectContent?.subject?.type === "selfplaced"  ? (props?.subjectContent?.subject?.subjectStatus !== "Pending Review" && props?.subjectContent?.subject?.subjectStatus !== "Not Started") : true;



  const clickTab = (value) => {
    setTab(value);
  };

  return (
    <>

      {loading && <SkeletonSubjectHeadBox />}

      {!loading && props?.qualification && <ProgramHeadBox isAdmin={props?.isAdmin} subjectContent={props?.qualification} content={props?.qualification} updateSubject={props?.updateSubject} statisticsData={props?.qualification?.progress} />}      
        <div className="lecture-detail-bottom">
          <div className="lecture-tabs-wrap programme__page">
            {props?.qualification?.is_show_overview ? <ul className="nav nav-pills course-nav-sticky" id="pills-tab" role="tablist">
              <li className="nav-item" title="Overview" onClick={() => clickTab("overview")}>
                <span className={`nav-link ${tab == "overview" ? "active" : ""}`}>
                  <i className="fal fa-id-card"></i>
                  Overview
                </span>
              </li>
              
              {/* <li className="nav-item" title="Programme Gradebook" onClick={() => clickTab("programme_gradebook")}>
                <span className={`nav-link ${tab == "programme_gradebook" ? "active" : ""}`}>
                  <i className="fal fa-book-alt"></i>
                  Programme Gradebook
                </span>
              </li> */}

              {/* {showShortCourse ? 
              <>
              {props?.subjectContent?.subject?.hide_learning_material ==0 ? <li className="nav-item" title="Learning Material" onClick={() => clickTab("assembly")}>
                <span
                  className={`nav-link ${tab == "assembly" ? "active" : ""}`}
                >
                  <i className="fal fa-books"></i>Learning Material
                </span>
              </li> : null}
              </>: null} */}              
            </ul> : null}
            <div className="tab-content" id="pills-tabContent">            
              {!loading ? (
                <div role="tabpanel" aria-labelledby="pills-Overview-tab">
                  <div className="scroll-course-list">
                    {(props?.qualification?.is_show_overview && props?.qualification?.overview) &&
                      new DOMParser()
                        .parseFromString(props?.qualification?.overview, "text/html")
                        .body.textContent.trim() ? (
                      <div
                        className="overview-program"
                        title={props?.qualification?.overview.replace(/<\/?[^>]+(>|$)/g, "").trim()}
                      >
                        {ReactHtmlParser(props?.qualification?.overview) || ""}
                      </div>
                    ) : null}
                                      
                    <SubjectGroupList setShowSideBar={props?.setShowSideBar} showSideBar={props?.showSideBar} completed_subject={props?.completed_subject} subjectList={props?.subjectList} subjectContent={props?.subjectList}  updateSubject={props?.updateSubject} isAdmin={props?.isAdmin} qid={props?.qid}    />
                  </div>
                </div>
              ) : null}

              {/* {tab === "programme_gradebook" && !loading ? (
                <div role="tabpanel" aria-labelledby="pills-ProgrammeGradebook-tab">
                  <h3>Programme Gradebook Content</h3>
                </div>
              ) : null} */}

            </div>
          </div>
        </div>
    </>
  );
};

/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    modalOpen: (payload) => dispatch(modalOpen(payload)),
  };
}

// export default React.memo(SubjectContent);
export default connect(mapDispatchToProps)(React.memo(ProgramContent));

