import React, { useState, useEffect, useRef, lazy, Suspense, Children } from "react";
import SideBar from "../components/common/SideBar";
import {
  GetMyPastQualificationService,
  GetMyQualificationService,
  GetShortCourseQualification,
} from "../services/QualificationService";
import SkeletonQaulification from "../loaders/SkeletonQualification";
import QualificationBox from "../components/course/QualificationBox";
import { getCurrentTimeHomePage } from "../components/common/Helper";
import { UpdateStudentTimeZone } from "../services/CalendarService";
import PastQualificationBox from "../components/course/PastQualificationBox";
import Header from "../components/common/Header";
import { useSelector } from "react-redux";
import { clearIsDownloadAssessment } from "../store/actions";

const MyCourses = () => {
  const [isPast, setIsPast] = useState(0);
  const [qualUnreadMessage, setQualUnreadMessage]=useState(0)
  const [shortUnreadMessage, setShortUnreadMessage]=useState(10)
  const [qualifications, setQualifications] = useState([]);
  const [pastQualification, setPastQualification] = useState([]);
  const [shortCourse, setShortCourse] = useState([]);
  const [pastShortCourse, setPastShortCourse]=useState([])
  const [loading, setLoading] = useState(true);
  const [showQual, setShowQual] = useState(true);
  const [totalQual, setTotalQual] = useState(0);
  const [totalSCourses, setTotalSCourses] = useState(0);
  const [showTabs, setShowTabs] = useState(false);
  const [error, setError] = useState(null);
  const [showQualification, setShowQualification]=useState(false)
  const [showShortCourse, setShowShortCourse]=useState(false)
  const chatNotification = useSelector((state) => state.chatNotification);
  const [total, setTotal]=useState(0)
  const [totalPast, setTotalPast]=useState(0)
  const [limit, setLimit]=useState(8)
  const [pastLimit, setPastLimit]=useState(8)
  const [loadingPast, setLoadingPast]=useState(false)
  const [showPast, setShowPast]=useState(false)
  const handleScroll = () => {

    if (!loading && (showQual ? qualifications.length < total : shortCourse.length < total) && total !== limit) {
      setLimit((prevLimit) => prevLimit + 10);
    }
    // if (
    //   window.innerHeight + document.documentElement.scrollTop >=
    //   document.documentElement.offsetHeight - 50 
    // ) {
      
    // }
  };

  const handlePastScroll = () => {
    if (!loadingPast && (showQual ? pastQualification.length < totalPast : pastShortCourse.length < totalPast) && totalPast !== pastLimit) {
      setPastLimit((prevPastLimit) => prevPastLimit + 10);
    }
  };
  
  useState(()=>{
    clearIsDownloadAssessment()
  },[])

  useEffect(() => {

    // DO NOT REMOVE THIS CODE
    setShowPast(false);
    const timer = setTimeout(() => {
      setShowPast(true);
    }, 500); 
  
    return () => {
      clearTimeout(timer);
    };
  }, [showQual]); 
  
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, qualifications.length, total,totalPast]);

  useEffect(() => {
    const fetchQualifications = async () => {
      setLoading(true);
      setError(null); // Reset error state on new fetch
      let qualificationData=[]
      try {
        const resCurrent = await GetMyQualificationService({ isPast: 0, limit:limit });
        if(resCurrent?.data?.data.length > 0){
          qualificationData=resCurrent?.data?.data
          setQualifications(resCurrent?.data?.data || []);         
          setTotal(resCurrent.data?.total)
          
          // findUnReadMessageCount(qualificationData,[])
        }
        if(resCurrent?.data?.isShortCoursesExists > 0){
          setShowShortCourse(true)
        } 
        if(resCurrent?.data?.data.length > 0){
          setShowQualification(true)
        }   
        setShowTabs(true);
      } catch (error) {
        console.error(error);
        setError("Failed to load qualifications.");
      } finally {
        setLoading(false); // Ensure loading is set to false after the operation
      }
  }
     
    const fetchShortCourses = async () => {
      setLoading(true);
      setError(null); // Reset error state on new fetch
      try {
        const res = await GetShortCourseQualification({isPast:0,limit:limit});
        const response = res?.data?.data || [];
        const newShortCourses = response?.filter(item => !item?.isPast);
        const newPastShortCourses = response?.filter(item => item?.isPast);
        // findUnReadMessageCount([],res?.data?.data)
        setTotal(res?.data?.total)
        setShortCourse(newShortCourses);
        // setPastShortCourse(newPastShortCourses);
      } catch (error) {
        console.error("Error fetching short courses: ", error);
        setError("Failed to load short courses.");
      } finally {
        setLoading(false); // Ensure loading is set to false after the operation
      }
    };
  
    const updateStudentTimeZone = async () => {
      try {
        const res = await UpdateStudentTimeZone({
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        if (res.status === 200) {
          // Handle success if needed
        }
      } catch (err) {
        console.log(err);
      }
    };
  
    if (showQual) {
      fetchQualifications();
    } else {
      fetchShortCourses();
    }
  
    updateStudentTimeZone();
  }, [showQual,limit]);

  useEffect(()=>{
    const fetchPastQualifications = async () => {
      try {
        if(isPast === 1){
          setLoadingPast(true)
        const resPast = await GetMyPastQualificationService({ isPast: 1, limit:pastLimit });
        if(resPast?.data?.data?.length > 0){
          setTotalPast(resPast?.data?.total)  
          setPastQualification(resPast?.data?.data || []);
          // findUnReadMessageCount(resPast?.data?.data,[])
          if(resPast?.data?.data?.length < 1){
          setShowQual(false)
          setShowQualification(false)
          setLoadingPast(false)
        }else{
          setShowQual(true)
          setShowQualification(true)
        }
          setShowTabs(true);
          setLoadingPast(false)
        }setLoadingPast(false)
      }
    }
      catch (error) {
        console.error(error);
        setError("Failed to load past qualifications.");
      }
    }

    const fetchPastShortCourses = async () => {
      setLoadingPast(true);
      setError(null); // Reset error state on new fetch
      try {
        const res = await GetShortCourseQualification({isPast:1,limit:pastLimit});
        const response = res?.data?.data || [];
        const newShortCourses = response?.filter(item => !item?.isPast);
        const newPastShortCourses = response?.filter(item => item?.isPast);
        // findUnReadMessageCount([],res?.data?.data)        
        setTotalPast(res?.data?.total)
        setPastShortCourse(newPastShortCourses);
      } catch (error) {
        console.error("Error fetching past short courses: ", error);
        setError("Failed to load past short courses.");
      } finally {
        setLoadingPast(false); // Ensure loading is set to false after the operation
      }
    };

    if(isPast === 1 && showQual){
    fetchPastQualifications()
    }else if(isPast === 1 && !showQual){
    fetchPastShortCourses()
    }
  },[showQual,isPast,pastLimit])

  useEffect(()=>{
    if(chatNotification?.chatnotificationcount){
      setQualUnreadMessage(chatNotification.chatnotificationcount.qualChatcount)
      setShortUnreadMessage(chatNotification.chatnotificationcount.shortChatcount)
    }
  },[chatNotification.chatnotificationcount])
  
  // useEffect(()=>{
  //   setQualUnreadMessage(0)
  //   setShortUnreadMessage(0)
    
  // },[showQual])

  const findUnReadMessageCount = (qual, short) => {
    let totalQualUnreadMessages = 0
    let totalShortUnreadMessages = 0
    if(qual?.length > 0){
    totalQualUnreadMessages = qual?.reduce((total, item) => {
      return total + (item.commentCount || 0); 
    }, 0);
    }
    if(short?.length > 0){
    totalShortUnreadMessages = short?.reduce((total, item) => {
      return total + (item.commentCount || 0); 
    }, 0);
    }
    setQualUnreadMessage((prev) => prev + (totalQualUnreadMessages));
    setShortUnreadMessage((prev) => prev + (totalShortUnreadMessages));
  };

  return (
    <>
        <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="mycourses-list-wrap mycourses-edit tab-sec">
            <div className="my-tickets-heading-blk flex-direction mb-4">
              <h4 className={`text-left-align landing-heading heading_color_dynamic my-courses-title ${!showTabs && 'hideTab__courses'}`}>
                My <span> Courses</span>
              </h4>
              <div className="text-md-right">
                <p className=""></p>
              </div>
              <div className="lecture-tabs-wrap lecture-time-box">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  {showTabs && (
                    <>
                      {showQualification && <li className={`nav-item ${showQual ? 'active' : ''}`} title="Qualifications">
                        <a
                          id="pills-Posts-tab"
                          data-toggle="pill"
                          href="#pills-Posts"
                          role="tab"
                          aria-controls="pills-Posts"
                          aria-selected="true"
                          className="nav-link active"
                          onClick={() => {setShowQual(true);setIsPast(0);setLimit(8)}}
                        >
                          <i className="far fa-graduation-cap" aria-hidden="true"></i>
                          Qualifications
                          {qualUnreadMessage > 0 && (
                            <div className="nav-fa-comments my-crs-head" title="You have unread messages">
                              <small className="notify-count">{qualUnreadMessage < 10 ? qualUnreadMessage : "9+"}</small>
                              <i className="fal fa-comments"></i>
                            </div>
                          )}
                        </a>
                      </li>}

                     {showShortCourse && <li className={`nav-item`} title="Short Courses">
                        <a
                          className={`nav-link ${!showQual ? 'active' : ''}`}
                          id="pills-Overview-tab"
                          data-toggle="pill"
                          href="#pills-Overview"
                          role="tab"
                          aria-controls="pillsOverview"
                          aria-selected="false"
                          onClick={()=>{setShowQual(false);setIsPast(0);setLimit(8)}}
                        >
                          <i className="fal fa-award"></i>
                          Short Courses
                          {shortUnreadMessage > 0 && (
                            <div className="nav-fa-comments my-crs-head" title="You have unread messages">
                              <small className="notify-count">{shortUnreadMessage < 10 ? shortUnreadMessage : "9+"}</small>
                              <i className="fal fa-comments"></i>
                            </div>
                          )}
                        </a>
                      </li>}
                    </>
                  )}
                </ul>
                <p className="date-time"> {getCurrentTimeHomePage(Date().toLocaleString())}</p>
              </div>
            </div>
            <div className="my-courses-list-wrap" 
            style={{
              maxHeight: "40vh"
            }}
            onScroll={(e) => {
              const element = e.target;
              const threshold = 1; // Adjust for precision issues
              if (element.scrollHeight - element.scrollTop - element.clientHeight <= threshold) {
                handleScroll();
              }
            }}
            >
            {loading && <SkeletonQaulification />}  
            {(!loading && ((qualifications?.length > 0 && showQual) || showShortCourse)) &&  (
              <QualificationBox
                showQualification={showQual}
                qualification={showQual ? qualifications : shortCourse}
                showProgram={true}
                state={{ isPast, qualifications, pastQualification, shortCourse, loading, showQual, totalQual, totalSCourses, showTabs }}
              />
            )}
            </div>
            {/* {(!loading && ((pastQualification?.length > 0) || (!showQual && pastShortCourse?.length > 0))) ? */}
              <>
                <button
                  data-toggle="collapse"
                  data-target="#show-hide-past-qualification"
                  aria-expanded="false"
                  className={`hide collapsed ${showQual ? "hide-completed-program" : "hide-completed-course"}`}
                  onClick={()=>{setIsPast(1)}}

                ></button> 
                {showPast &&
                <div className="collapse" id="show-hide-past-qualification">
                {loadingPast && <SkeletonQaulification />}
                {!loadingPast ?(pastQualification?.length > 0 || pastShortCourse?.length > 0) ? 
                <div className="my-courses-list-wrap" 
                style={{maxHeight: "40vh"}}
                onScroll={(e) => {
                  const element = e.target;
                  const threshold = 1; // Adjust for precision issues
              
                  if (
                    element.scrollHeight - element.scrollTop - element.clientHeight <= threshold
                  ) {
                    handlePastScroll();
                  }
                }}
                >
                <PastQualificationBox
                
                  state={{ isPast, pastQualification, pastShortCourse, loading, showQual, totalQual, totalSCourses, showTabs }}
                />
                </div>
                : 
                <div className="w-100 text-center">No past {showQual ? "qualifications" : "short courses"}</div>
                :null}
              </div>
              }
              </>
              {/* : null} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCourses;
