import React, { useState, useEffect } from "react";
import { image_url } from "../../utils/ImageUrl";
import { TrimText } from "../common/TrimText";

export default function TeamProfile(props) {
  const { team, profile } = props;

  return (
    <>
      <div className="p-0">
        <div className="support-team-box">
          <div className="support-team-top">
            <div className="support-team-icon" title={team?.name || "NA"}>
              <img src={image_url(team?.picture)} />
            </div>

            <div className="support-team-detail">
              <h3 className="support-team-name" title={team.name || "NA"}>
                {(team && team.name) && team.name}
              </h3>
              {profile == "coach" ? (
                <p className="support-team-subject">Programme Coordinator</p>
              ) : (
                ""
              )}
              {profile == "lecturer" ? (
                <p className="support-team-subject">Subject Lecturer</p>
              ) : (
                ""
              )}
              {profile == "support" ? (
                <p className="support-team-subject">Programme Support</p>
              ) : (
                ""
              )}
              {team && team.email ? <p className="support-team-email" title={team?.email}>
                <a href={"mailto:" + team.email}>{team?.email}</a>
              </p> : null}

            </div>
          </div>
          <div className="support-team-bottom">
          {team && team.about ? <p className="support-team-about" title={team?.about}>
            {team && team.about ? TrimText(team?.about,170) : "NA"}
          </p> : null}
        </div>
        </div>
        
      </div >

      {/* <div className="col support-team-col">
        <div className="support-team-box">
          <div className="support-team-icon">
            <img src={image_url(team?.picture)} />

          </div>
          <div className="support-team-detail">
            <h3 className="support-team-name">
              {team && team.name && team.name}
            </h3>
            {profile == "coach" ? (
              <p className="support-team-subject">Programme Lead</p>
            ) : (
              ""
            )}
            {profile == "lecturer" ? (
              <p className="support-team-subject">Subject Lecturer</p>
            ) : (
              ""
            )}
            {profile == "support" ? (
              <p className="support-team-subject">Programme Support</p>
            ) : (
              ""
            )}
            <p className="support-team-email">
              {team && team.email && team.email ? (<a href={"mailto:"+ team.email}>{team.email}</a>) : ("")}
            </p>
            <p className="support-team-detail">
              {team && team.about && team.about}
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
}
