import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { getOneAssemblieNote, updateAssembliesNote } from "../../services/AssembliesService";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";

export default function UpdateAssembliesNote(props) {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    intake_id: "",
    notes_content: "",
    assemblies_items_id: "",
    id:""
  });
  const { note_id, subjectId ,setRefresh} = props;
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    let mounted = true;

    getOneAssemblieNote({ id: note_id })
      .then((res) => {
        if (mounted) {
          setInitialValues({
            intake_id: subjectId,
            notes_content: res.data?.data?.notes_content,
            assemblies_items_id: res.data?.data?.assemblies_items_id,
            id: note_id,
          });
          setLoading(false);
          props.setRefresh(Date.now())
        }
      })
      .catch((error) => {
        console.error("Error fetching notes:", error);
        setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [note_id, subjectId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      notes_content: Yup.string().required("Notes is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const formInputs = new FormData();
      formInputs.append("intake_id", subjectId);
      formInputs.append("notes_content", values.notes_content);
      formInputs.append("id", note_id);
      formInputs.append("assemblies_items_id",values.assemblies_items_id)
      updateAssembliesNote(formInputs)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Updated!",
            text: "Note has been updated successfully.",
            timer: 3000,
            onOpen: function () {
              Swal.showLoading();
            },
          }).then(function (result) {
            setLoading(false);
            if (result.dismiss === "timer") {
              props.closeEditNotes(false);
              setRefresh(Date.now())
            }
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: error?.message,
            timer: 3000,
            onOpen: function () {
              Swal.showLoading();
            },
          })
        });
    },
  });

  return (
    <>
      {loading && <SkeletonTicketList />}
      {!loading && <div className="Tickets-main-wrap notes-main-wrap">
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-xl-12 form-group">
                <label title="Edit Note">
                  <b>Edit Note</b>
                </label>
                <textarea
                title={formik.values.notes_content}
                  type="text"
                  name="notes_content"
                  className={
                    "note-input form-control" +
                    (formik.errors.notes_content && formik.touched.notes_content
                      ? " is-invalid"
                      : "")
                  }
                  placeholder="Add any notes you would like to take."
                  value={formik.values.notes_content}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.notes_content &&
                  formik.errors.notes_content && (
                    <div className="invalid-feedback d-block">
                      {formik.errors.notes_content}
                    </div>
                  )}
              </div>
              <div className="form-group atttach-file m-0 col-12 d-flex ">
                <div className="" title="Save">
                  <button
                    type="submit"
                    className="btn btn__save__note"
                    disabled={loading}
                  >
                    {loading && <i className="fas fa-cog fa-spin"></i>}{" "}
                    <i className="fal fa-save"></i>
                    Save
                  </button>
                </div>
                <div className="" title="Cancel">
                  <button
                    type="button"
                    className="btn btn-danger btn__save__cancel"
                    onClick={() => props.closeEditNotes(false)}
                  >
                    {/* <i className="fal fa-times"></i> */}
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    }
    </>
  );
}
