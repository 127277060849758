import React, { useEffect, useState } from "react";
import { deleteAssembliestNote, getNotesAssembliesList, updateAssembliesNote } from "../../services/AssembliesService";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import moment from "moment";
import Swal from "sweetalert2";
import UpdateAssembliesNote from "./UpdateAssembliesNote";

export default function LessonNotesList(props) {
  const {timestamp,setPlayerSeek,editNoteId,setEditNoteId,setEditNote,editNote,minimized, refresh, setRefresh,closeEditNotes,setVideoPlay}=props
  const [notesList, setNotesList] = useState([]);
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    let mounted = true;

    getNotesAssembliesList({ assemblies_items_id: props.lessonItemId,intake_id:props.subjectId })
      .then((res) => {
        if (mounted) {
          setNotesList(res.data?.data?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching notes:", error);
        setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [props.lessonItemId,refresh]);

  const handleDeleteNote = (item_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAssembliestNote({ id: item_id })
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Note has been deleted successfully.",
              timer: 3000,
              onOpen: function () {
                Swal.showLoading();
              },
            })
            setRefresh(Date.now())
          })
          .catch((error) => {
            console.log(error)
            Swal.fire({
              icon: "error",
              title: "Error!",
              text: error?.message,
              timer: 3000,
              onOpen: function () {
                Swal.showLoading();
              },
            })
      });
      }
    });
  };

  function HandleEditNote(note_id){
    setEditNoteId(note_id)
    setEditNote(true)    
  }

  const formatTimestamp = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');
    const hours = duration.hours();
    const minutes = duration.minutes();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds();
    
    return hours > 0 ? `${hours}:${formattedMinutes}:${formattedSeconds}` : `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleVideoJump=(time)=>{
    setPlayerSeek(time)
    props.resumeFromTime(time)
    // setVideoPlay(Date.now())
    // timestamp()
    // props.resumeVideoPlayer()
  }

  return (
    <>
      {loading && <SkeletonTicketList />}
      {notesList?.length > 0 && <div className="notes-main-wrap">
        {((!loading && !editNote) || minimized) &&
          <>
            <p className="notes-instruction" title="Saved Notes">Saved Notes</p>
            <div className="notes-card-wrap">
              {notesList.map((note, index) => (
                <div key={index} className={`notes-card ${notesList.length > 1 ? "notes__card__box" : null}`}>
                  <div className="notes-clip">
                    <p className="video-clip-note" onClick={()=>handleVideoJump(note?.video_end_duration)} title={`Play Video Clip ${formatTimestamp(note?.video_end_duration )} - ${formatTimestamp(note?.video_end_duration)}`}><i className="fal fa-play" /><span>Play Video Clip {formatTimestamp(note?.video_start_duration)} - {formatTimestamp(note?.video_end_duration)}</span></p>
                    <div className="video-clip-created">
                      <p title={`Created ${moment(note?.created_at).format("h:mm, DD MMMM YYYY")}`}>Created {moment(note?.created_at).format("h:mm, DD MMMM YYYY")}</p>
                      <p title={`Last Modified ${moment(note?.updated_at).format("h:mm, DD MMMM YYYY")}`}>Last Modified {moment(note?.updated_at).format("h:mm, DD MMMM YYYY")}</p>
                    </div>    
                    <div className="notes-content" title={`${note?.notes_content}`}>
                      {note?.notes_content}
                    </div>
                    <div className="notes-button"> 
                      <button className="btn-edit-notes" type="button" onClick={() => HandleEditNote(note.id)} title="Edit Note"><i className="fal fa-edit" /><span>Edit Note</span></button>
                      <button className="btn-delete-notes" type="button" onClick={() => handleDeleteNote(note.id)} title="Delete Note"><i className="fal fa-times" /><span>Delete Note</span></button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        }
        {!loading && !minimized && editNote && <UpdateAssembliesNote note_id={editNoteId} setRefresh={setRefresh} subjectId={props.subjectId} closeEditNotes={setEditNote} />}
      </div>}
    </>
  );
}
