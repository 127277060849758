import React, { useEffect, useRef, useState } from "react";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";

const ObjectDetection = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 570, height: 500 });

  useEffect(() => {
    let animationFrameId;
    
    const setupCamera = async () => {
      if (!navigator.mediaDevices?.getUserMedia) {
        console.error('getUserMedia is not supported');
        return;
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: "user",
            width: { ideal: dimensions.width },
            height: { ideal: dimensions.height }
          }
        });

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          // Wait for video metadata to load to get actual dimensions
          await new Promise((resolve) => {
            videoRef.current.onloadedmetadata = () => {
              setDimensions({
                width: videoRef.current.videoWidth,
                height: videoRef.current.videoHeight
              });
              resolve();
            };
          });
        }

        const model = await cocoSsd.load();
        detectFrame(videoRef.current, model);
      } catch (error) {
        console.error('Error setting up camera:', error);
      }
    };

    const detectFrame = (video, model) => {
      if (!video || !model) return;

      model.detect(video).then((predictions) => {
        const accuratePredictions = predictions.filter(
          prediction => 
            (prediction.class === "person" || prediction.class === "cell phone") && 
            prediction.score > 0.7
        );
        renderPredictions(accuratePredictions);
        animationFrameId = requestAnimationFrame(() => detectFrame(video, model));
      });
    };

    const renderPredictions = (predictions) => {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext("2d");
      
      if (!ctx || !canvas) return;

      // Clear the canvas and set it to match video dimensions
      canvas.width = dimensions.width;
      canvas.height = dimensions.height;
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      // Font options
      const font = "16px sans-serif";
      ctx.font = font;
      ctx.textBaseline = "top";

      predictions.forEach((prediction) => {
        const [x, y, width, height] = prediction.bbox;
        
        // Draw bounding box
        ctx.strokeStyle = "#FF0000";
        ctx.lineWidth = 2;
        ctx.strokeRect(x, y, width, height);

        // Draw label background
        ctx.fillStyle = "#FF0000";
        const textWidth = ctx.measureText(prediction.class).width;
        const textHeight = parseInt(font, 10);
        ctx.fillRect(x, y, textWidth + 4, textHeight + 4);

        // Draw text
        ctx.fillStyle = "#000000";
        ctx.fillText(prediction.class, x, y);
      });
    };

    setupCamera();

    return () => {
      // Cleanup
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      if (videoRef.current?.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  return (
    <div className="">
      <video
        ref={videoRef}
        className="size"
        autoPlay
        playsInline
        muted
        style={{ width: dimensions.width, height: dimensions.height }}
        width="570"
        height="500"
      />
      <canvas
        ref={canvasRef}
        className="size"
        style={{ width: dimensions.width, height: dimensions.height }}
        width="570"
        height="500"
      />
    </div>
  );
};

export default ObjectDetection;