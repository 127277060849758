import React, { useEffect, useState } from "react";
import SchoolOfEngineering from "./SchoolOfEngineering";
import SchoolOfBusiness from "./SchoolOfBusiness";
import SchoolOfDraughting from "./SchoolOfDraughting";
import SchoolOfArchitecture from "./SchoolOfArchitecture";
import SchoolOfGreenside from "./SchoolOfGreenside";
import SchoolOfIT from "./SchoolOfIT";
import SchoolOfBeauty from "./SchoolOfBeauty";
import SchoolOfArtisans from "./SchoolOfArtisans"

import SchoolOfEngineeringReadOnly from "./read_only_school/SchoolOfEngineeringReadOnly";
import SchoolOfBusinessReadOnly from "./read_only_school/SchoolOfBusinessReadOnly";
import SchoolOfDraughtingReadOnly from "./read_only_school/SchoolOfDraughtingReadOnly";
import SchoolOfArchitectureReadOnly from "./read_only_school/SchoolOfArchitectureReadOnly";
import SchoolOfGreensideReadOnly from "./read_only_school/SchoolOfGreensideReadOnly"
import SchoolOfITReadOnly from "./read_only_school/SchoolOfITReadOnly";
import SchoolOfBeautyReadOnly from "./read_only_school/SchoolOfBeautyReadOnly";
import SchoolOfArtisansReadOnly from "./read_only_school/SchoolOfArtisansReadOnly";


import {
  getReRegistrationSubjectService,
  getReRegistrationReadOnlySubjectService,
  CheckProgressionSubject,
} from "../../services/ReRegistrationService";
import { Link } from "react-router-dom";
import SchoolOfEmendy from "./SchoolOfEmendy";
import SchoolOfEmendyReadOnly from "./read_only_school/SchoolOfEmendyReadOnly";
// import BachleorP2 from "./BachleorP2Form";
import BachleorP2Form from "./BachleorP2Form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Skeleton from "react-loading-skeleton";
import SkeletonForm from "../common/SkeletonForm";
function ReRegistrationRightBox(props) {
  const {data : registrationList} = useSelector(state => state.registrations)
  const { registration } = props;
  const {regid,qualification_higher_enrolled } = useParams()
  const [reRegSubject, setReRegSubject] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [studentNewRegId, setStudentNewRegId] = useState(0);
  const [lateReg, setLateReg] = useState(0);
  const [subjectProgressReadOnly, setSubjectProgressReadOnly] = useState([]);
  const [subjectReWriteReadOnly, setSubjectReWriteReadOnly] = useState([]);
  const [subjectReDoReadOnly, setSubjectReDoReadOnly] = useState([]);
  const [subjectReSubReadOnly, setSubjectReSubReadOnly] = useState([]);
  const [regForReadOnly, setRegForReadOnly] = useState([]);
  const [repeatReadOnly, setRepeatReadOnly] = useState([]);
  const [electiveReadOnly, setElectiveReadOnly] = useState([]);
  const [delivery_methode, setDelivery_methode] = useState('');
  const [isBachleorForm,setIsBachleorForm] = useState(false)
  const [isDataInBachleor, setIsDataInBachleor] = useState(true);
  const [readOnlyData,setReadOnlyData] = useState({});
  const [loading,setLoading] = useState()

  // useEffect(() => {
  //   CheckProgressionSubject({qualification_assign_id : registrationList?.open[0].qualification_higher_enrolled})
  //   .then((res) => {
  //     setIsBachleorForm(res.data)
  //   })
  // },[registrationList])
  // -------------- when data is not there normal form should be shown ----------------------------
  

  const checkForm = (idToSend) => {
    setLoading(true)
    CheckProgressionSubject({qualification_assign_id : idToSend})
    .then((res) => {
      setLoading(false)
      setIsBachleorForm(res.data)
    }).catch((err) => {
      setLoading(false)
      console.log(err);
    })
  }

  useEffect(() => {
    checkForm(qualification_higher_enrolled)
  },[qualification_higher_enrolled])

  useEffect(() => {
    getReRegistrationSubjectService(props.registration.id).then((res) => {
      setReRegSubject(res.data.result);
      setLateReg(res.data.late_reg)
    });
  }, [registrationList.open]);

  useEffect(() => {
    if (
      readOnly ||
      props.registration.readOnly ||
      props.registration.studentNewRegId > 0
    ) {
      let regId = props.registration.studentNewRegId
        ? props.registration.studentNewRegId
        : studentNewRegId;
      getReRegistrationReadOnlySubjectService(regId).then((res) => {
        setSubjectProgressReadOnly(res.data.subjectProgress_);
        setSubjectReWriteReadOnly(res.data.subjectRewrite_);
        setSubjectReDoReadOnly(res.data.subjectRedo_);
        setDelivery_methode(res.data.delivery_methode);
        setRegForReadOnly(res.data.registerFor);
        setRepeatReadOnly(res.data.repeat);
        setElectiveReadOnly(res.data.elective);
        setReadOnlyData(res.data);
      });
      setReadOnly(true);
    }
  }, [readOnly]);
  useEffect(() => {
    if(!isDataInBachleor){
      setIsBachleorForm(false)
    }
  },[isDataInBachleor])

  return (
    <>
      <div className="re-registration-detail-rgt">
        <div className="re-registration-detail-top gap-2 d-flex justify-content-between">
          <div className="re-registration-detail-top-left">
            <div className="re-registration-bx-info d-flex">
              <div className="icon">SA</div>
              <div className="re-registration-bx-info-rgt">
                <h3>
                  {registration.academy2}, Intake {registration.intake_number} ,{" "}
                  {registration.intake_year}
                </h3>
                <p>
                  <i className="fal fa-calendar-alt"></i>{" "}
                  {registration.start_date} to {registration.end_date}
                </p>
              </div>
            </div>
          </div>
          <div className="re-registration-detail-top-right">
            <a title="Back" class="btn ml-50 btn-white-bordered" href="/reregistration"><i class="fal fa-angle-left"></i>Back</a>
          </div>
        </div>

        <div className="re-registration-register-bx">
          <div className="card card-form-bx">
            <div className="card-header">
              {/* What would you like to Re-Register for next term? */}
            </div>
            {isBachleorForm && <BachleorP2Form ateReg={lateReg}
                reRegSubject={reRegSubject}
                bachleorData={isBachleorForm}
                readOnlyData={readOnlyData}
                isData={isDataInBachleor}
                setIsData={setIsDataInBachleor}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}/>}
            {!readOnly && !isBachleorForm &&
              registration &&
              registration.academy == "draughting" ? (
              <SchoolOfDraughting
                lateReg={lateReg}
                reRegSubject={reRegSubject}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}
              />
            ) : null}
            {!readOnly && !isBachleorForm &&
              registration &&
              registration.academy == "engineering" ? (
              <SchoolOfEngineering
                lateReg={lateReg}
                reRegSubject={reRegSubject}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}
              />
            ) : null}
            {!readOnly && !isBachleorForm && registration && registration.academy == "business" ? (
              <SchoolOfBusiness
                lateReg={lateReg}
                reRegSubject={reRegSubject}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}
              />
            ) : null}
            {!readOnly && !isBachleorForm &&
              registration &&
              registration.academy == "architecture" ? (
              <SchoolOfArchitecture
                lateReg={lateReg}
                reRegSubject={reRegSubject}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}
                nextOption={props.registration.next_year_option}
              />
            ) : null}
            {!readOnly && !isBachleorForm &&
              registration &&
              registration.academy == "gdc" ? (
              <SchoolOfGreenside
                lateReg={lateReg}
                reRegSubject={reRegSubject}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}
              />
            ) : null}
             {!readOnly && !isBachleorForm &&
              registration &&
              registration.academy == "IT" ? (
              <SchoolOfIT
                lateReg={lateReg}
                reRegSubject={reRegSubject}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}
              />
            ) : null}
                         {!readOnly && !isBachleorForm &&
              registration &&
              registration.academy == "beauty" ? (
              <SchoolOfBeauty
                lateReg={lateReg}
                reRegSubject={reRegSubject}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}
              />
            ) : null}
            {!readOnly && !isBachleorForm &&
              registration &&
              (registration.academy == "fst" || registration.academy == "fg") ? (
              <SchoolOfEmendy
                lateReg={lateReg}
                reRegSubject={reRegSubject}
                registration={registration}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                setStudentNewRegId={setStudentNewRegId}
                updateStatus={props.updateStatus}
              />
            ) : null}
            {/* ReadOnly && !isBachleorForm ReRegistration */}
            {readOnly && !isBachleorForm &&
              registration &&
              registration.academy == "draughting" ? (
              <SchoolOfDraughtingReadOnly
                readOnlyData={readOnlyData}
                reRegSubject={reRegSubject}
                registration={registration}
                subjectProgressReadOnly={subjectProgressReadOnly}
                subjectReWriteReadOnly={subjectReWriteReadOnly}
                subjectReDoReadOnly={subjectReDoReadOnly}
                subjectReSubReadOnly={subjectReSubReadOnly}
                delivery_methode={delivery_methode}
              />
            ) : null}
            {readOnly && !isBachleorForm &&
              registration &&
              registration.academy == "engineering" ? (
              <SchoolOfEngineeringReadOnly
                readOnlyData={readOnlyData}
                reRegSubject={reRegSubject}
                registration={registration}
                subjectProgressReadOnly={subjectProgressReadOnly}
                subjectReWriteReadOnly={subjectReWriteReadOnly}
                subjectReDoReadOnly={subjectReDoReadOnly}
                subjectReSubReadOnly={subjectReSubReadOnly}
              />
            ) : null}
            {readOnly && !isBachleorForm && registration && registration.academy == "business" ? (
              <SchoolOfBusinessReadOnly
                readOnlyData={readOnlyData}
                reRegSubject={reRegSubject}
                registration={registration}
                subjectReDoReadOnly={subjectReDoReadOnly}
              />
            ) : null}
                  {readOnly && !isBachleorForm && registration && registration.academy == "beauty" ? (
              <SchoolOfBeautyReadOnly
                    readOnlyData={readOnlyData}
                reRegSubject={reRegSubject}
                registration={registration}
                subjectReDoReadOnly={subjectReDoReadOnly}
              />
            ) : null}
            {readOnly && !isBachleorForm && registration && registration.academy == "artisans" ? (
              <SchoolOfArtisansReadOnly
                reRegSubject={reRegSubject}
                registration={registration}
                subjectReDoReadOnly={subjectReDoReadOnly}
              />
            ) : null}
                  {readOnly && !isBachleorForm && registration && registration.academy == "IT" ? (
              <SchoolOfITReadOnly
                    readOnlyData={readOnlyData}
                reRegSubject={reRegSubject}
                registration={registration}
                subjectReDoReadOnly={subjectReDoReadOnly}
              />
            ) : null}
            {readOnly && !isBachleorForm &&
              registration &&
              registration.academy == "architecture" ? (
              <SchoolOfArchitectureReadOnly
                readOnlyData={readOnlyData}
                reRegSubject={reRegSubject}
                registration={registration}
                subjectProgressReadOnly={subjectProgressReadOnly}
                subjectReWriteReadOnly={subjectReWriteReadOnly}
                subjectReDoReadOnly={subjectReDoReadOnly}
                subjectReSubReadOnly={subjectReSubReadOnly}
                nextOption={props.registration.next_year_option}
              />
            ) : null}
            {readOnly && !isBachleorForm &&
              registration &&
              registration.academy == "gdc" ? (
              <SchoolOfGreensideReadOnly
                readOnlyData={readOnlyData}
                reRegSubject={reRegSubject}
                registration={registration}
                regForReadOnly={regForReadOnly}
                repeatReadOnly={repeatReadOnly}
              />
            ) : null}
            {readOnly && !isBachleorForm &&
              registration &&
              (registration.academy == "fst" || registration.academy == "fg") ? (
              <SchoolOfEmendyReadOnly
                readOnlyData={readOnlyData}
                reRegSubject={reRegSubject}
                registration={registration}
                regForReadOnly={regForReadOnly}
                repeatReadOnly={repeatReadOnly}
                electiveReadOnly={electiveReadOnly}
              />
            ) : null}
            {/* <SchoolOfEngineering reRegSubject={reRegSubject} /> */}
            {/* <SchoolOfBusiness reRegSubject={reRegSubject} /> */}
            {/* <SchoolOfDraughting reRegSubject={reRegSubject} /> */}
            {/* <SchoolOfArchitecture reRegSubject={reRegSubject} /> */}
          
          </div>
        </div>
      </div>
    </>
  );
}

export default ReRegistrationRightBox;