import React, { useEffect, useState } from "react";
import { object } from "underscore";
import TeamProfile from "./TeamProfile";
import { GetSupportTeamService } from "../../services/QualificationService";
import SkeletonReRegistrationList from "../../loaders/SkeletonReRegistrationList";

export default function TeamBox(props) {
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    let mounted = true
    GetSupportTeamService({ sid: props.subjectId })
      .then((res) => {
        if (mounted) {
          setTeam(res.data.team);
          setLoading(false)
        }
      })
      .catch((error) => { 
        setLoading(false)
      });

    return function cleanup() {
      mounted = false
    }
  }, []);

  return (
    <>
      {loading && <div className="row support-team-skeleton"><SkeletonReRegistrationList /></div>}
      {props.subject__welcome && Object.keys(team).length !== 0 &&
        new DOMParser().parseFromString(props.subject__welcome, "text/html").body.textContent.trim() && <hr style={{ margin: "20px 0" }} />
      }
      {!loading && Object.keys(team).length !== 0 ? (
        <>
          <div className="support-team-heading">Support Team</div>
          <div className="container-fluid p-0">
            <div className="row mx-0">
              {team.coach && (
                <div className="col-lg-4 col-md-6 p-0" ><TeamProfile team={team.coach} profile="coach" /></div>
              )}
              {team.lecturer && (
                <div className="col-lg-4 col-md-6 p-0"><TeamProfile team={team.lecturer} profile="lecturer" /></div>
              )}
              {team.support && (
                <div className="col-lg-4 col-md-6 p-0"><TeamProfile team={team.support} profile="support" /></div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
