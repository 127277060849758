import React, { useEffect, useState, useMemo, useRef } from "react";
import graphIcon from "../../../assets/images/graph.png";
import { quizResultWithTime } from "../../../services/QuizService";
import { longAndShortAns } from "../../../services/QuizService";
import ReactHtmlParser from "react-html-parser";
import { convertArray } from "../../common/Helper";
import QuizBar from "./QuizBar";
import moment from "moment";
import { useSelector } from "react-redux";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import axios from "axios";
import { API_BASE, STUDENT_FILE_DOWNLOAD } from "../../../utils/Constants";

// import { Bar } from "@reactchartjs/react-chart.js";

export default function ReviewQuiz(props) {
  const { setshow, quizId, rId } = props;
  const user = useSelector((state) => state.user);
  const [quiz, setquiz] = useState({});
  const [maximumLimit, setmaximumLimit] = useState(false);
  const [ansSheet, setAnsSheet] = useState({
    timeArray: [],
    questionArray: [],
  });
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    quizResultWithTime({ rid: rId })
      .then((res) => {
        setquiz(res.data[0]);
        setmaximumLimit(res.data[1]);
      })
      .catch((err) => console.log(err));
  }, []);

  const secToMin = (value) => {
    var timestamp = value;
    var hours = Math.floor(timestamp / 60 / 60);
    var minutes = Math.floor(timestamp / 60) - hours * 60;
    var seconds = timestamp % 60;
    // hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    var formatted =
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0");
    return formatted;
  };

  useEffect(() => {
    let data = {
      rid: rId,
    };
    setLoading(true);
    longAndShortAns(data)
      .then((res) => {
        setLoading(false)
        // console.log("___________longAndShortAns____________", res);
        setAnsSheet(res.data);
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
      });
  }, []);

  const date = (value) => {
    const timestamp = new Date(value * 1000);
    return moment(timestamp).format("MM/DD/YYYY h:mm a");
    // return new Intl.DateTimeFormat("en-US", {
    //   year: "numeric",
    //   month: "2-digit",
    //   day: "2-digit",
    //   hour: "2-digit",
    //   minute: "2-digit",
    //   second: "2-digit",
    // }).format(timestamp);
  };

  // console.log("____________________________", ansSheet);

  //const AnsSheetAll =



  const handleDocumentDownload = async (submittedFiles) =>{
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    const files = submittedFiles.map(f=> STUDENT_FILE_DOWNLOAD + f.replaceAll("/home/myaie/public_html/","").replace("public/",""))
    const zip = new JSZip();

    const downloadPromises = files.map(async (fileUrl) => {
      const response = await axios.get(fileUrl, { responseType: 'blob' });
      const fileData = response.data;

      const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
      zip.file(fileName, fileData);
    });

    await Promise.all(downloadPromises);

    const zipFile = await zip.generateAsync({ type: 'blob' });
    Swal.close();
    saveAs(zipFile, 'files.zip');
    // Swal.fire({
    //   title: "File downloading",
    //   onOpen: function () {
    //     Swal.showLoading();
    //   },
    // });
    // const zip = new JSZip();
    // try{
    //   if(Array.isArray(submittedFiles) && submittedFiles.length){
    //     let p = Promise.all(submittedFiles.map(async (item, index)=>{
    //       if(item && item !== "" && item !== null){
    //         const fileName = item.split("/").reverse()[0];
    //         const fileURL = `${item.includes("s3.af-south-1.amazonaws.com") ? "" : API_BASE.replaceAll('/api/v1','') + "/"}${encodeURI(item.replaceAll(
    //           "/home/myaie/public_html/","").replace("public/",""))}`;
    //         try{
    //           // console.log("_________ file URL",fileURL);
    //           const getFile = await axios({
    //             url : fileURL,
    //             method : 'get',
    //             responseType : 'arraybuffer',              
    //           });
    //           // console.log("____ download Files:",getFile);
    //           zip.file(`${fileName}`, getFile.data, {binary:true});
    //         }catch(errrr){
    //           console.log(errrr)
    //           Swal.close();
    //           return;    
    //         }

    //       }
    //     }))
    //     p.then((res)=>{
    //       zip.generateAsync({type : 'blob'}).then((content)=>{
    //         // console.log("____ content:",content);
    //         saveAs(content, `submitted_file_ques_upload` + ".zip");
    //       }).catch((err1)=>{console.log(err1)})
    //     }).catch((err)=>{
    //       console.log(err)
    //     })
        
    //   }
    // }catch(err){
    //   console.log(err)  
    // }finally{
    //   Swal.close()
    // }
  };

  return (
    <div>
      {/* <div class="main-action-btns text-right">
        <a
          onClick={() => setshow({ list: true })}
          class="btn btn-white-bordered  btn-back m-0"
        >
          <i class="fal fa-angle-left"></i>Back to Learning Material 
        </a>
        <a
          onClick={() => setshow({ attempt: true })}
          class="btn btn-white-bordered"
        >
          <i class="fal fa-clipboard-list"></i>Attempted Quiz Result List
        </a>
        <a
          onClick={() => {
            if (maximumLimit) {
              setshow({ desc: true });
            }
          }}
          class="btn btn-white-bordered"
          // style={{
          //   cursor: maximumLimit ? "pointer" : "not-allowed",
          // }}
          style={
            maximumLimit
              ? {
                  cursor: "pointer",
                }
              : {
                  cursor: "not-allowed",
                  backgroundColor: "#d7dee8",
                }
          }
        >
          <i class="fal fa-redo-alt fa-flip-horizontal"></i>Attempt Again
        </a>
      </div> */}
      <div class="card card-header-transparent">
        {/* <div class="card-header">{quiz && quiz.quiz_name}</div> */}
        <div class="card-body">
          <div class="form-group" style={{marginBottom:"15px"}}>
            <label className="m-0">
              <strong>
                {user && user.first_name} {user && user.second_name}{" "}
                {user && user.last_name}
              </strong>
            </label>
          </div>

          <div class="table-responsive table__quize__desc">
            <table class="table table-bordered table-striped table-custom-info">
              <tbody>
                <tr>
                  <th>Score</th>
                  <td>
                    {quiz && quiz.score_obtained && ~~quiz.score_obtained}
                  </td>
                </tr>
                <tr>
                  <th>Time Spent (Approx.)</th>
                  <td>{quiz && secToMin(quiz.total_time)} Min.</td>
                </tr>
                <tr>
                  <th>Attempt Time</th>
                  <td>{quiz && date(quiz.start_time)}</td>
                </tr>
                <tr>
                  <th>Percentages</th>
                  <td>
                    {quiz && quiz.percentage_obtained
                      ? ~~quiz.percentage_obtained + "%"
                      : "0%"}
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>{(quiz && quiz.result_status === "pass") ? "Pass" : "Fail" }</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card card-header-transparent">
        <div class="card-header">
          Time spent on individual question (in seconds)
        </div>
        {loading ? <SkeletonTicketList /> : <div class="card-body">
          <div class="quiz-graph">
            {ansSheet.timeArray.length > 0 && (
              <QuizBar
                timeArr={ansSheet.timeArray[0].split(",")}
                questionArray={ansSheet.questionArray}
                scoreArray={ansSheet.scoreArray}
                dataAns={ansSheet.dataAns}
              />
            )}
          </div>
        </div>}
      </div>

      {quiz && quiz.view_answer == 1 ? (
        <div class="card card-header-transparent">
          <div class="card-header">Answer Sheet</div>
          {loading ? <SkeletonTicketList/> :<div class="card-body">
            {ansSheet &&
              ansSheet["dataAns"] &&
              ansSheet["dataAns"].map((allAns, i) => {
                var sty = "card card-quiz-answer ";
                if (allAns.questionType === "Document Upload" || allAns.questionType === "Long Answer"){
                  sty += "card-header-yellow";
                } else if(ansSheet.scoreArray[i] === "0"){
                  sty += "card-header-red";
                } else if (
                  allAns.score == ansSheet.scoreArray[i] &&
                  allAns.questionType != "Long Answer"
                ) {
                  sty += "card-header-green";
                } else {
                  sty += "card-header-blue";
                }
                return (
                  <>
                    {(allAns.questionType != "Match the Column" && allAns.questionType !== "Order List") && (
                      <>
                        {/*  */}
                        <div class={sty}>
                          <div class="card-header">Question {i + 1}</div>
                          <div class="card-body">
                            <div class="table-responsive">
                              <table class="table table-bordered table-striped table-custom-info">
                                <tbody>
                                  <tr>
                                    <th>Question:</th>
                                    <td>
                                      {ReactHtmlParser(allAns.questionName)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Your Answer:</th>
                                    <td>
                                      {allAns.questionType === "Document Upload" 
                                        ? <a href="javascript:void(0)" onClick={()=>handleDocumentDownload([...allAns.answerdDocument.map(d=>d.docs)])}>Download Submission</a>
                                        : ReactHtmlParser(allAns.UserAnswer)
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Correct Options:</th>
                                    <td>
                                      {allAns.questionType === "Document Upload" 
                                        ? "-"
                                        : ReactHtmlParser(allAns.correctAnswer)
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                      </>
                    )}

                    {(allAns.questionType == "Match the Column" || allAns.questionType === "Order List") && (
                      <React.Fragment>
                        {/* ///// */}
                        <div class={sty}>
                          <div class="card-header">Question {i + 1}</div>
                          <div class="card-body">
                            <div class="table-responsive">
                              <table class="table table-bordered table-striped table-custom-info table-column-3">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Your Answer</th>
                                    <th>Correct Answer</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{allAns.questionName}</td>
                                    <td>
                                      {/* {ReactHtmlParser(
                                        convertArray(allAns.UserAnswer)
                                      )} */}
                                      {ReactHtmlParser(allAns.UserAnswer)}
                                    </td>
                                    <td>
                                      {/* {ReactHtmlParser(
                                        convertArray(allAns.correctAnswer)
                                      )} */}
                                      {ReactHtmlParser(allAns.correctAnswer)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {/* ///// */}
                      </React.Fragment>
                    )}
                  </>
                );
              })}
          </div>}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
