import axiosInstance from "../utils/axios";
import axios from 'axios';

const cancelTokens = {
  GetSubjectEvent: null,
  GetSubject: null,
  GetSubjectEventPageWise: null,
  UpdateStudentTimeZone: null,
  GetSubjectEventTzCalender: null
};

export const GetSubjectEvent = async (values) => {
  if (cancelTokens.GetSubjectEvent) {
    cancelTokens.GetSubjectEvent.cancel('New request initiated');
  }
  cancelTokens.GetSubjectEvent = axios.CancelToken.source();

  return await axiosInstance().post(`/getSubjectEventTz`, values, {cancelToken: cancelTokens.GetSubjectEvent.token});
};

export const GetSubject = async () => {
  if (cancelTokens.GetSubject) {
    cancelTokens.GetSubject.cancel('New request initiated');
  }
  cancelTokens.GetSubject = axios.CancelToken.source();

  return await axiosInstance().get(`/getAllSubjectCalendar`, {cancelToken: cancelTokens.GetSubject.token});
};

export const GetSubjectEventPageWise = async (values) => {
  if (cancelTokens.GetSubjectEventPageWise) {
    cancelTokens.GetSubjectEventPageWise.cancel('New request initiated');
  }
  cancelTokens.GetSubjectEventPageWise = axios.CancelToken.source();

  return await axiosInstance().post(`/getSubjectEventPageWiseTz`, values, {cancelToken: cancelTokens.GetSubjectEventPageWise.token });
};

export const UpdateStudentTimeZone = async (values) => {
  if (cancelTokens.UpdateStudentTimeZone) {
    cancelTokens.UpdateStudentTimeZone.cancel('New request initiated');
  }
  cancelTokens.UpdateStudentTimeZone = axios.CancelToken.source();

  return await axiosInstance().post(`/updateStudentTimeZone`, values, {cancelToken: cancelTokens.UpdateStudentTimeZone.token});
};

export const GetSubjectEventTzCalender = async (values) => {
  if (cancelTokens.GetSubjectEventTzCalender) {
    cancelTokens.GetSubjectEventTzCalender.cancel('New request initiated');
  }
  cancelTokens.GetSubjectEventTzCalender = axios.CancelToken.source();

  return await axiosInstance().post(`/getSubjectEventTzCalender`, values, {cancelToken: cancelTokens.GetSubjectEventTzCalender.token});
};