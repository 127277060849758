import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { download } from "../../utils/FileDownload";
import moment from "moment";
import randomColor from "randomcolor";
import $ from "jquery";
// import PuzzleImg from '../../assets/images/puzzle-icon.svg';
import { TrimText } from "../common/TrimText";
import { formatDateRange, getSubjectStatus, subjectDuration } from "../../utils/commonFunction";
import MentoredSubject from "../course/MentoredSubject";
import ViewProfile from "../course/ViewProfile";
import ReactHtmlParser from "react-html-parser";
const stc = require("string-to-color");
const downloadFile = async (absolutePath, fileName) => {
  await download(absolutePath, fileName);
};

export default function SubjectGroupList({
  subjectList,
  qualification,
  qid,
  completed_subject,
  showSideBar,
  setShowSideBar
}) {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sideBarShow, setSideBarShow] = useState(false)

  const [color, setColor] = useState(null);
  const popRef=useRef(null)
  useEffect(() => {
    setColor(
      randomColor({
        luminosity: "dark",
      })
    );
  }, [qualification]);

  function createAbbreviation(name = "") {
    return (
      name.split(" ")[0].toString().toUpperCase().substring(0, 1) +
      (name.split(" ")[1]
        ? name.split(" ")[1].toString().toUpperCase().substring(0, 1)
        : "")
    );
  }

  useEffect(() => {
    if (window.innerWidth < 769) {
      setShowSideBar(false)
    } else {
      setShowSideBar(true)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [showSideBar]);



  const getTextLength = () => {
    if (windowWidth < 321) {
      return 76;
    }

    if (windowWidth < 376) {
      return 90;
    }

    if (windowWidth < 426) {
      return 110;
    }

    if (windowWidth < 769) {
      return 90;
    }

    if (windowWidth < 1025) {
      return showSideBar ? 80 : 135;
    }

    if (windowWidth < 1442) {
      return showSideBar ? 145 : 200;
    }

    if (windowWidth < 1920) {
      return showSideBar ? 180 : 230;
    }

    if (windowWidth < 2560) {
      return showSideBar ? 235 : 350;
    }

    return 235
  };

  return (
    <>
      <div className="row row-custome couses-newrw-lists">
        {subjectList?.map((ele, i) => {
          // if (ele?.subject_type === "shortcourse") {
          const icon = getSubjectStatus(ele?.icon);
          return (
            <Link
              to={`/course/${qid}/subject/${ele?.intake_id}`}
              className="col-lg-6 col-md-6 col-xs-12 qualification-box"
              key={i}
              title={ele?.name}
              onClick={(e) => {
                if (ele?.subjectStatus === "Not Yet Published") {
                  e.preventDefault(); // Prevent navigation
                }
              }}
              ref={popRef}
            >
              <div
                className={`couses-renew-card qual-tab ${ele?.subjectStatus === "Not Yet Published"
                  ? " short-course-disabled"
                  : ""
                  }`}
              >
                <>
                  {ele?.unreadMessage != 0 && <div className="nav-tpFixed-comments card-comment-box" title='You have unread messages'>
                    <span className="nav-fa-comments card-comments">
                      <small className="notify-count">{ele?.unreadMessage ? ele?.unreadMessage < 10 ? ele?.unreadMessage : "9+" : 0}</small>
                      <i className="fal fa-comments notification-icon"></i>
                    </span>
                  </div>}

                  <div className="course-box w-100 d-flex flex-column justify-content-between h-100">
                    <div className="d-flex flex-column">
                      {ele?.code ? <div className="program-course-title">
                        <span className="tittle" title={ele?.code}>{ele?.code}</span>
                      </div> : null}

                      <div className="program-tittle">
                        <h2 title={ele?.name}>
                          {ele?.name ? TrimText(ele?.name, 50) : ""}
                        </h2>
                      </div>

                      <div className="d-flex flex-direction-column align-items-center program-duration">
                        {ele?.type === "Self-Paced" ? (
                          <p
                            className="months-duration"
                            style={{
                              marginRight: "auto",
                              fontSize: "12px",
                            }}
                            title={`${ele?.subject_duration} months`}
                          >
                            <span className="couse_i_in">
                              <i className="fal fa-clock"></i>
                            </span>
                            {ele?.subject_duration} months
                          </p>
                        ) : (
                          <p className="date-duration">
                            <span className="couse_i_in">
                              <i className="fal fa-calendar-alt"></i>
                            </span>
                            {ele?.start && ele?.end ? (
                              <>
                                {formatDateRange(ele?.start, ele?.end, ele)}
                              </>
                            ) : "Invalid Date"}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="qualification-view align-items-start">
                      {(ele?.lecturer_name) ? <ViewProfile
                        user={{
                          lead_photo: ele?.lecturer_photo,
                          prog_lead_photo: ele?.lecturer_photo,
                          prog_lead_name: ele?.lecturer_name,
                          lead_activity_status: ele?.lecturer_status,
                          lead_email: ele?.lecturer_email,
                          lead_mobile: ele?.lecturer_mobile,
                          coach_name: ele?.intake_coach_name,
                          prog_coach_name: ele?.intake_coach_name,  
                          coach_photo: ele?.intake_coach_photo,
                          coach_email: ele?.intake_coach_email,
                          coach_mobile: ele?.intake_coach_mobile,
                          supp_photo: ele?.intake_supp_photo,
                          supp_fullname: ele?.intake_supp_name,
                          supp_activity_status: ele?.intake_supp_status,
                          supp_email: ele?.intake_supp_email,
                          supp_mobile: ele?.intake_supp_mobile,
                        }}
                        cardParent={true}
                      />
                      :
                      ele?.intake_coach_name ? <ViewProfile
                        user={{
                          lead_photo: ele?.intake_coach_photo,
                          photo: ele?.prog_coach_photo,
                          lead_name: ele?.intake_coach_name,
                          prog_lead_name: ele?.intake_coach_name,
                          lead_activity_status:
                            ele?.prog_lead_active == 1
                              ? "online"
                              : "offline",
                          lead_email: ele?.intake_coach_email,
                          lead_mobile: ele?.intake_coach_mobile,
                          coach_photo: ele?.prog_coach_photo,
                          coach_name: ele?.intake_coach_name,
                          prog_coach_name: ele?.intake_coach_name,
                          coach_activity_status:
                            ele?.prog_lead_active == 1
                              ? "online"
                              : "offline",
                          coach_email: ele?.intake_coach_email,
                          coach_mobile: ele?.intake_coach_mobile,
                          supp_photo: ele?.intake_supp_photo,
                          supp_fullname: ele?.intake_supp_name,
                          supp_activity_status:
                            ele?.prog_supp_active == 1
                              ? "online"
                              : "offline",
                          supp_email: ele?.intake_supp_email,
                          supp_mobile: ele?.intake_supp_mobile,
                        }}
                        parentBox={false}
                        disabled={ele?.subjectStatus == "Not Yet Published" ? true : false}
                        popRef={popRef}
                        index={i}
                        cardParent={false}
                      /> : 
                      ele?.intake_supp_name ? <ViewProfile user={{
                        lead_photo: ele?.intake_supp_photo,
                        prog_lead_name: ele?.intake_supp_name,
                        lead_activity_status: ele?.intake_supp_status,
                        lead_email: ele?.intake_supp_email,
                        lead_mobile: ele?.intake_supp_mobile,
                        // supp_photo: ele?.intake_supp_photo,
                        // supp_fullname: ele?.intake_supp_name,
                        // supp_activity_status: ele?.intake_supp_status,
                        // supp_email: ele?.intake_supp_email,
                        // supp_mobile: ele?.intake_supp_mobile,
                      }}/> :
                      null}
                    </div>
                    <div className={`d-flex flex-column justify-content-end`}>
                      {ele?.welcome && (
                        <div
                          className="course-description"
                          title={
                            new DOMParser()
                              .parseFromString(ele?.welcome, "text/html")
                              .body.textContent.replace(/\s+/g, " ")
                              .trim() || "NA"
                          }
                        >
                          {TrimText(
                            new DOMParser()
                              .parseFromString(ele?.welcome, "text/html")
                              .body.textContent.replace(/\s+/g, " ")
                              .trim() || "NA",
                            getTextLength()
                          )}
                        </div>
                      )}
                      <div className="d-flex align-items-end">
                        <div className="progress-program progress-statistics">
                          <div className="program-stats">
                            <div className="program-card-progress">
                              <span className="status" title={`${ele?.progress?.progressBar}% completed`}>
                                {ele?.progress?.progressBar}% completed
                              </span>
                              <div className="program-status" title={ele?.subjectStatus}>
                                <img src={icon} alt="" width="10px" height="10px" />
                                <p className="program-status-type">
                                  {ele?.subjectStatus}
                                </p>
                              </div>
                            </div>
                            <div className="progress" title={`${ele?.progress?.progressBar}%`}>
                              <div
                                className="progress-bar zero-progress bar-data"
                                role="progressbar"
                                style={{width: (ele?.progress?.progressBar || 5) + "%",
                                }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {ele?.progress?.progressBar}%
                              </div>
                            </div>
                          </div>

                        </div>
                        {/* <Link
                              to={`/course/${qid}/subject/${ele?.intake_id}`}
                              className="btn btn-primary open-course"
                            >
                              Open Course
                            </Link> */}
                      </div>
                      <div className="program-statistics-count-2">
                        <ul className="num-info">
                          {ele?.progress.total_videos > 0 && (
                            <li title="Videos" className="videoes">
                              <i className="fal fa-video" />
                              <span className="value">
                                {ele?.progress.total_videos}
                              </span>
                            </li>
                          )}
                          {ele?.progress.total_quizs > 0 && (
                            <li title="Quizzes" className="Quizzes">
                              <i className="fal fa-clipboard-list" />
                              <span className="value">
                                {ele?.progress.total_quizs}
                              </span>
                            </li>
                          )}
                          {ele?.progress.total_documents > 0 && (
                            <li title="Files" className="files">
                              <i className="fal fa-file" />
                              <span className="value">
                                {ele?.progress.total_documents}
                              </span>
                            </li>
                          )}
                          {ele?.progress.total_assessment > 0 && (
                            <li
                              title="Assessment"
                              className="files"
                            >
                              <i className="fal fa-ballot" />
                              <span className="value">
                                {ele?.progress.total_assessment}
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </Link>
          );
        })}
        {/* //completed subject List  */}
        {completed_subject?.map((ele, i) => {
          const icon = getSubjectStatus(ele?.icon);
          return (
            <Link
              to={`/course/${qid}/subject/${ele?.intake_id}`}
              className="col-lg-6 col-md-6 col-xs-12 qualification-box"
              key={i}
              onClick={(e) => {
                if (ele?.subjectStatus === "Not Yet Published") {
                  e.preventDefault(); // Prevent navigation
                }
              }}
              title={ele?.name}
            >
              <div
                className={` couses-renew-card qual-tab ${ele?.subjectStatus === "Not Yet Published"
                  ? " short-course-disabled"
                  : ""
                  }`}
              >
                <>
                  {ele?.unreadMessage != 0 && <div className="nav-tpFixed-comments card-comment-box" title='You have unread messages'>
                    <span className="nav-fa-comments card-comments">
                      <small className="notify-count">{ele?.unreadMessage ? ele?.unreadMessage < 10 ? ele?.unreadMessage : "9+" : 0}</small>
                      <i className="fal fa-comments notification-icon"></i>
                    </span>
                  </div>}

                  <div className="course-box w-100 d-flex flex-column justify-content-between h-100">
                    <div className="">
                      {ele?.code ? <div className="program-course-title">
                        <span className="tittle" title={ele?.code}>{ele?.code}</span>
                      </div> : null}

                      <div className="program-tittle">
                        <h2 title={ele?.name || ""}>
                          {ele?.name ? TrimText(ele?.name, 50) : ""}
                        </h2>
                      </div>

                      <div className="d-flex flex-direction-column align-items-center program-duration">
                        {ele?.type === "Self-Paced" ? (
                          <p
                            className="months-duration"
                            style={{
                              marginRight: "auto",
                              fontSize: "12px",
                            }}
                            title={`${ele?.subject_duration} months`}
                          >
                            <span className="couse_i_in">
                              <i className="fal fa-clock"></i>
                            </span>
                            {ele?.subject_duration} months
                          </p>
                        ) : (
                          <p className="date-duration" title={`${ele?.start ? moment.unix(ele?.start).local().format("D MMM YYYY") : ""} - ${ele?.end ? moment.unix(ele?.end).local().format("D MMM YYYY") : ""} ${subjectDuration(ele?.end, ele?.start)}`}>
                            <span className="couse_i_in">
                              <i className="fal fa-calendar-alt"></i>
                            </span>
                            {ele?.start && ele?.end ? (
                              <>
                                {formatDateRange(ele?.start, ele?.end, ele)}
                              </>
                            ) : "Invalid Date"}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="qualification-view align-items-start">
                      {ele?.intake_coach_name ? <ViewProfile
                        user={{
                          lead_photo: ele?.prog_lead_photo,
                          prog_lead_name: ele?.intake_coach_name,
                          lead_activity_status:
                            ele?.prog_lead_active == 1
                              ? "online"
                              : "offline",
                          lead_email: ele?.intake_coach_email,
                          lead_mobile: ele?.intake_coach_mobile,
                          supp_photo: ele?.intake_supp_photo,
                          supp_fullname: ele?.intake_supp_name,
                          supp_activity_status:
                            ele?.prog_supp_active == 1
                              ? "online"
                              : "offline",
                          supp_email: ele?.intake_supp_email,
                          supp_mobile: ele?.intake_supp_mobile,
                        }}
                        parentBox={true}
                        disabled={ele?.subjectStatus == "Not Yet Published" ? true : false}
                        popRef={popRef}
                        index={i}
                      /> : null}
                    </div>
                    <div className="d-flex flex-column justify-content-end">
                      {ele?.welcome && (
                        <div
                          className="course-description"
                          title={
                            new DOMParser()
                              .parseFromString(ele?.welcome, "text/html")
                              .body.textContent.replace(/\s+/g, " ")
                              .trim() || "NA"
                          }
                        >
                          {TrimText(
                            new DOMParser()
                              .parseFromString(ele?.welcome, "text/html")
                              .body.textContent.replace(/\s+/g, " ")
                              .trim() || "NA",
                            getTextLength()
                          )}
                        </div>
                      )}
                      <div className="d-flex align-items-end">
                        <div className="progress-program progress-statistics">
                          <div className="program-stats">
                            <div className="program-card-progress">
                              <span className="status" title={`${ele?.progress?.progressBar}% completed`}>
                                {ele?.progress?.progressBar}% completed
                              </span>
                              <div className="program-status" title={ele?.subjectStatus}>
                                <img src={icon} alt="" width="10px" height="10px" />
                                <p className="program-status-type">
                                  {ele?.subjectStatus}
                                </p>
                              </div>
                            </div>
                            <div className="progress" title={`${ele?.progress?.progressBar}%`}>
                              <div
                                className="progress-bar zero-progress bar-data"
                                role="progressbar"
                                style={{width: (ele?.progress?.progressBar || 5) + "%"}}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {ele?.progress?.progressBar}%
                              </div>
                            </div>
                          </div>

                        </div>
                        {/* <Link
                            to={`/course/${qid}/subject/${ele?.intake_id}`}
                            className="btn btn-primary open-course"
                          >
                            Open Course
                          </Link> */}
                      </div>
                      <div className="program-statistics-count-2">
                        <ul className="num-info">
                          {ele?.progress.total_videos > 0 && (
                            <li title="Videos" className="videoes">
                              <i className="fal fa-video" />
                              <span className="value">
                                {ele?.progress.total_videos}
                              </span>
                            </li>
                          )}
                          {ele?.progress.total_quizs > 0 && (
                            <li title="Quizzes" className="Quizzes">
                              <i className="fal fa-clipboard-list" />
                              <span className="value">
                                {ele?.progress.total_quizs}
                              </span>
                            </li>
                          )}
                          {ele?.progress.total_documents > 0 && (
                            <li title="Files" className="files">
                              <i className="fal fa-file" />
                              <span className="value">
                                {ele?.progress.total_documents}
                              </span>
                            </li>
                          )}
                          {ele?.progress.total_assessment > 0 && (
                            <li
                              title="Assessment"
                              className="files"
                            >
                              <i className="fal fa-ballot" />
                              <span className="value">
                                {ele?.progress.total_assessment}
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
}
